import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NoticeStatus } from 'store/ducks/notice/types';
import { AppState } from 'store/store';
import { IDocType, ISelectedDoc } from 'api/services/docs/types';
import { getUserByPinfl } from 'api/services/users/users';
import { IResUser } from 'api/services/users/types';
import { MainLoader } from 'components/Loader/Loader';
import SearchIcon from '@mui/icons-material/Search';
import Template from 'components/Template/Template';
import { ShowNotification } from 'helpers/showNotice';
import { FileUploads } from 'components/FileUploads/FileUploads';
import MaskInput from 'components/MaskInput/MaskInput';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  applicationsServices,
  schoolServices,
  usersServices,
} from 'api/services';
import {
  ErrorTypes,
  IApplicationDoc,
  IApplicationWithDoc,
  IEventData,
} from 'api/services/applications/types';
import { Event } from 'react-socket-io';
import { isReceptionRole, isSuRole } from 'helpers/checkRoles';
import { Lang, langs } from 'helpers/langs';
import { ISelectSchool } from 'pages/CreateApplicationPageByUser/CreateApplicationPageByUser';
import * as serviceActions from 'store/ducks/services/actions';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import './CreateApplicationPage.sass';
import Popup from 'components/Popup/Popup';
import { UserModal } from 'components/Popup/components/UserModal/UserModal';
import { PassModal } from 'components/Popup/components/PassModal/PassModal';

export const CreateApplicationPage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [userPhone, setUserPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [serial, setSerial] = useState('');
  const [number, setNumber] = useState('');
  const [pinfl, setPinfl] = useState('');
  const [searchUser, setSearchUser] = useState<IResUser>();
  const [vehicleType, setVehicleType] = useState<IVehicleType>();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [docTypes, setDocTypes] = useState<ISelectedDoc[]>([]);
  const [errorDocs, setErrorDocs] = useState<ISelectedDoc[]>([]);
  const [confirmUser, setConfirmUser] = useState<IResUser>();
  const [selectedDocs, setSelectedDocs] = useState<IApplicationDoc[]>([]);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { user } = useSelector((state: AppState) => state.users);
  const [applicationLang, setApplicationLang] = useState('');
  const [initEvent, setInitEvent] = useState(true);
  const [selectedSchoolId, setSelectedSchoolId] = useState<number>();
  const [selectedRegion, setSelectedRegion] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([]);
  const [typeError, setTypeError] = useState<ErrorTypes>();
  const [applicationId, setApplicationId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [expire083, setExpire083] = useState('');
  const [expireMainDoc, setExpireMainDoc] = useState('');
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [schools, setSchools] = useState<ISelectSchool[]>([]);
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.CreateApplicationPage
  );
  const [isForeigner, setIsForeigner] = useState('')
  const selectedCategoryRef = useRef<string>();
  const docTypesRef = useRef<ISelectedDoc[]>();
  const selectedDocsRef = useRef<IApplicationDoc[]>([]);
  const dispatch = useDispatch();
  const { categories, regions } = useSelector(
    (state: AppState) => state.services
  );
  const userModalRef = useRef<any>();
  const passModalRef = useRef<any>();

  useEffect(() => {
    scannerSettings();
    getSchools();
    return () => {
      setInitEvent(false);
      if (isReceptionRole(user)) {
        (window as any).disconnect();
      }
    };
  }, []);

  useEffect(() => {
    getServices();
  }, []);

  const scannerSettings = () => {
    const connectScanner = (window as any).connect;
    if (connectScanner && isReceptionRole(user)) {
      connectScanner();
    }
  };

  const createApplication = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user?.branchId) return;

    if (!selectedCategory) {
      setHasError(true);
      ShowNotification(i18n.fillFields);
      return;
    }

    if (!vehicleType) {
      setHasError(true);
      ShowNotification(i18n.vehicleType);
      return;
    }
    if (!expire083.trim()) {
      setHasError(true);
      ShowNotification('Заполните срок мед. справки');
      return;
    }
    if (!expireMainDoc.trim()) {
      setHasError(true);
      ShowNotification('Заполните срок паспорта');
      return;
    }
    const requiredDocs = docTypes.filter((i) => i.required === true);
    setErrorDocs(
      requiredDocs.filter(
        (i) => !selectedDocs.some((ii) => ii.docId === i.id)
      ) || !selectedDocs.length
    );
    if (selectedDocs.length < requiredDocs.length) {
      setHasError(true);
      ShowNotification(i18n.uploadDocuments);
      return;
    }

    if (!applicationLang.length) {
      setHasError(true);
      ShowNotification(i18n.selectLanguage);
      return;
    }

    if (!selectedSchoolId) {
      setHasError(true);
      ShowNotification(i18n.choiceSchool);
      return;
    }

    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !dateOfBirth.trim() ||
      !selectedRegion || !isForeigner.trim()
    ) {
      setHasError(true);
      ShowNotification('Заполните поля');
      return;
    }
    const newUser = {
      firstName,
      lastName,
      serial,
      number,
      pinfl,
      phone: userPhone,
      middleName,
      dateOfBirth,
    } as IResUser;

    const applicationData: IApplicationWithDoc = {
      categoryId: Number(selectedCategory),
      ...(userId ? { userId } : {}),
      docs: selectedDocs.map((i) => ({ docId: i.docId, src: i.src })),
      vehicleType: vehicleType,
      user: searchUser ?? newUser,
      lang: applicationLang as Lang,
      schoolId: selectedSchoolId,
      regionId: selectedRegion ? Number(selectedRegion) : null,
      expire083,
      expireMainDoc,
      branchId: user?.branchId,
      isForeigner: isForeigner === 'true' ? true : isForeigner === 'false' ? false : undefined
    };
    try {
      setLoading(true);
      const res = await applicationsServices
        .createApplication(applicationData)
        .then((res) => res.data);
      setApplicationId(res.id.toString());
      ShowNotification(i18n.applicationSuccessCreated, NoticeStatus.SUCCESS);
      if (isSuRole(user) || isReceptionRole(user)) {
        history(`/applications/${res.id}`);
        return;
      }
    } catch (e: any) {
      setHasError(false);
      const message = e?.response?.data?.message;
      if (
        message[0] ===
        'vehicleTypeId must be a number conforming to the specified constraints'
      ) {
        ShowNotification(i18n.vehicleType);
        return;
      }
      switch (message) {
        case 'user_category':
          ShowNotification(i18n.applicationAlreadyExists);
          return;
        case 'pinfl':
          setTypeError('PINFL');
          const pnflUser = await usersServices
            .getUserByParams({ pinfl })
            .then((res) => res.data);
          setConfirmUser(pnflUser);
          userModalRef.current.onShow();
          return;
        case 'passport':
          setTypeError('PASSPORT');
          const passportUser = await usersServices
            .getUserByParams({ serial, number })
            .then((res) => res.data);
          setConfirmUser(passportUser);
          userModalRef.current.onShow();
          return;
        case 'phone':
          setTypeError('PHONE');
          const phoneUser = await usersServices
            .getUserByParams({ phone: userPhone })
            .then((res) => res.data);
          setConfirmUser(phoneUser);
          userModalRef.current.onShow();
          return;
        case 'Not enough files':
          ShowNotification(i18n.uploadDocuments);
          return;
        case 'Branch not found':
          ShowNotification(
            'Ваш профиль не прикреплен к филлиалу, обратитесь к администратору'
          );
          return;
        default:
          return ShowNotification(i18n.error);
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmPass = async (pass: string) => {
    if (pass.length < 9) return;
    try {
      setLoading(true);
      await applicationsServices.passBinding({
        applicationId: Number(applicationId),
        pass,
      });
      ShowNotification(i18n.passIssued, NoticeStatus.INFO);
      history(`/applications`);
    } catch {
      ShowNotification(i18n.errorTryLater);
      setLoading(false);
    }
  };

  const setUserData = (resUser: IResUser) => {
    const {
      firstName,
      lastName,
      serial,
      middleName,
      number,
      dateOfBirth,
      id,
      pinfl,
    } = resUser;
    setFirstName(firstName || '');
    setLastName(lastName || '');
    setMiddleName(middleName || '');
    setSerial(serial || '');
    setNumber(number || '');
    setUserId(id);
    setDateOfBirth(
      moment(dateOfBirth).format('YYYY-MM-DD') !== 'Invalid date'
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : ''
    );
    setPinfl(pinfl || '');
  };

  const searchUserByPhone = async () => {
    if (userPhone.length < 12) {
      ShowNotification(i18n.errorPhone);
      setHasError(true);
      return;
    }
    try {
      const res = await usersServices
        .getUserByPhone(userPhone)
        .then((res) => res.data);
      setConfirmUser(res);
      userModalRef.current.onShow();
    } catch (e: any) {
      if (e.response.data.statusCode === 404) {
        ShowNotification(i18n.userNotFound);
        return;
      }
      ShowNotification(i18n.error);
    } finally {
      setHasError(false);
    }
  };

  const selectCategory = (categoryId: string) => {
    setSelectedCategory(categoryId);
    selectedCategoryRef.current = categoryId;
    const currentCategory = categories.find((i) => i.id === Number(categoryId));
    if (!currentCategory) return;
    const resultDocs = currentCategory.docs.map((i) => {
      const findImages = docTypes.find((ii) => ii.id === i.id);
      return {
        type: i.name as IDocType,
        id: i.id,
        required: i.required,
        ...(findImages?.src ? { src: findImages.src } : {}),
      };
    });
    setDocTypes(resultDocs);
    docTypesRef.current = resultDocs;
    setVehicleTypes(
      currentCategory.vehicleTypes?.sort((a, b) => a.localeCompare(b))
    );
  };

  const getServices = () => {
    if (!categories.length) dispatch(serviceActions.getCategories());

    if (!regions.length) dispatch(serviceActions.getRegions());
  };

  const selectFile = (src: string, id: number) => {
    setErrorDocs((prev) => prev.filter((i) => i.id !== id));
    setSelectedDocs((prev) => {
      const clone = _.cloneDeep(prev);
      const deep = clone.filter((i) => i.docId !== id);
      const findDoc = docTypes.find((i) => i.id === id);
      selectedDocsRef.current = [
        ...deep,
        { src, docId: id, required: findDoc?.required || true },
      ];
      return [...deep, { src, docId: id, required: !!findDoc?.required }];
    });
  };

  const searchUserByPinfl = async () => {
    if (!pinfl || pinfl?.length < 13) {
      setHasError(true);
      ShowNotification(i18n.errorPinfl);
      return;
    }
    try {
      const resUser = await getUserByPinfl(pinfl).then((res) => res.data);
      setConfirmUser(resUser);
      userModalRef.current.onShow();
    } catch (e: any) {
      if (e.response.data.statusCode === 404) {
        ShowNotification(i18n.userNotFound);
      } else {
        ShowNotification(i18n.error);
      }
    } finally {
      setHasError(false);
    }
  };

  const closeUserModal = () => {
    userModalRef.current.onHide();
    setTypeError(undefined);
    setSearchUser(undefined);
  };

  const closeHandler = () => {
    setTypeError(undefined);
    setSearchUser(undefined);
  }

  const acceptUser = (user: IResUser) => {
    const validationUser = {
      ...user,
      phone: user.phone || userPhone,
      firstName: user.firstName || firstName,
      lastName: user.lastName || lastName,
      middleName: user.middleName || middleName,
      dateOfBirth: user.dateOfBirth || dateOfBirth,
      serial: user.serial || serial,
      number: user.number || number,
      pinfl: user.pinfl || pinfl,
    };
    setSearchUser(validationUser);
    setUserData(validationUser);
    setTypeError(undefined);
    setUserId(validationUser.id);
    setUserPhone(validationUser.phone);
    if (user.photo) {
      const photoId = docTypes?.find((i) => i.type === 'PHOTO')?.id;
      if (!photoId) return;
      const newPhotoDoc: IApplicationDoc = {
        docId: photoId,
        name: 'PHOTO',
        src: user.photo,
        required: true,
      };
      const findPhotoDocIndex = selectedDocs.findIndex(
        (i) => i.docId === newPhotoDoc.docId
      );
      let newArrDocs = selectedDocs;
      if (
        findPhotoDocIndex >= 0 &&
        selectedDocs.some((i) => i.docId === photoId)
      ) {
        newArrDocs.splice(findPhotoDocIndex, 1, newPhotoDoc);
      } else {
        newArrDocs = [...selectedDocs, newPhotoDoc];
      }
      selectedDocsRef.current = newArrDocs;
      setSelectedDocs(newArrDocs);

      const newDoc: ISelectedDoc = {
        id: photoId,
        src: user.photo,
        type: 'PHOTO',
        required: true,
      };
      setErrorDocs((prev) => prev.filter((i) => i.type !== 'PHOTO'));
      const docsFindIndx = docTypes.findIndex((i) => i.id === newDoc.id);
      const newArrSplice = docTypes;
      newArrSplice.splice(docsFindIndx, 1, newDoc);
      setDocTypes(newArrSplice);
      docTypesRef.current = newArrSplice;
    }
  };

  const checkSerialNumberPassport = async () => {
    if (!serial.length || !number.length) {
      ShowNotification(i18n.enterPassport);
      setHasError(true);
      return;
    }
    try {
      const resUser = await usersServices
        .getUserBySerialNumber(serial, number)
        .then((res) => res.data);
      setConfirmUser(resUser);
      userModalRef.current.onShow();
    } catch (e: any) {
      if (e.response.data.statusCode === 404) {
        ShowNotification(i18n.userNotFound);
      } else {
        ShowNotification(i18n.error);
      }
    } finally {
      setHasError(false);
    }
  };

  const subscribeApplicationData = async (data: IEventData) => {
    const categoryRefId = selectedCategoryRef.current;
    const docsRef = docTypesRef.current;
    if (!categoryRefId || !docsRef) {
      return;
    }
    const {
      firstName,
      middleName,
      documentName,
      scan,
      serial,
      number,
      lastName,
      dateOfBirth,
      pinfl,
      expireMainDoc,
    } = data;
    setFirstName(firstName);
    if (middleName) {
      setMiddleName(middleName);
    }
    setNumber(number);
    setLastName(lastName);
    setDateOfBirth(moment(dateOfBirth).format('YYYY-MM-DD'));
    setExpireMainDoc(moment(expireMainDoc).format('YYYY-MM-DD'));
    setPinfl(pinfl);
    setSerial(serial);
    const findDoc = docsRef?.find((i) => i.type === documentName);
    if (!findDoc) return;
    const newDoc: ISelectedDoc = {
      id: findDoc?.id,
      src: scan,
      type: findDoc?.type,
      required: !!findDoc?.required,
    };
    const newSelectedDoc: IApplicationDoc = {
      docId: findDoc?.id,
      src: scan,
      name: findDoc?.type,
      required: !!findDoc?.required,
    };
    const docsFindIndx = docsRef.findIndex((i) => i.type === 'PHOTO');
    const newArrSplice = docsRef;
    newArrSplice.splice(docsFindIndx, 1, newDoc);
    const selectedResult = selectedDocsRef.current.filter(
      (i) => i.name !== documentName && i.docId !== findDoc.id
    );
    selectedDocsRef.current = [...selectedResult, newSelectedDoc];
    setSelectedDocs([...selectedResult, newSelectedDoc]);
    setDocTypes(newArrSplice);
    docTypesRef.current = newArrSplice;
  };

  const subscribeApplicationDataByScanner = (data: IEventData) => {
    const categoryRefId = selectedCategoryRef.current;
    const docsRef = docTypesRef.current;
    if (!categoryRefId || !docsRef) {
      return;
    }
    const { documentName, scan } = data;
    const findDoc = docsRef?.find((i) => i.type === documentName);
    if (!findDoc) return;
    const newDoc: ISelectedDoc = {
      id: findDoc?.id,
      src: scan,
      type: findDoc?.type,
      required: !!findDoc.required,
    };
    const newSelectedDoc: IApplicationDoc = {
      docId: findDoc?.id,
      src: scan,
      name: findDoc?.type,
      required: !!findDoc.required,
    };
    const selectedResult = selectedDocsRef.current.filter(
      (i) => i.docId !== newSelectedDoc.docId
    );
    const docsFindIndx = docsRef.findIndex((i) => i.id === newDoc.id);
    const newArrSplice = docsRef;
    newArrSplice.splice(docsFindIndx, 1, newDoc);
    selectedDocsRef.current = [...selectedResult, newSelectedDoc];
    setSelectedDocs([...selectedResult, newSelectedDoc]);
    setDocTypes(newArrSplice);
    docTypesRef.current = newArrSplice;
  };

  const clearFields = () => {
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setUserPhone('');
    setSerial('');
    setNumber('');
    setUserId(undefined);
    setDateOfBirth('');
    setPinfl('');
    const newDocTypesArray = docTypes.map((i) => ({
      ...i,
      src: '',
    }));
    docTypesRef.current = newDocTypesArray;
    setDocTypes(newDocTypesArray);
  };

  const getSchools = async () => {
    const params = {
      skip: page * take,
      take,
    };
    const res = await schoolServices.getSchools(params).then((res) => res.data);
    setSchools(
      res.data.map((i) => ({
        ...i,
        label: i.title,
      }))
    );
  };

  const request = _.debounce(async (value) => {
    const params = {
      skip: page * take,
      take,
      search: value,
    };
    const res = await schoolServices.getSchools(params).then((res) => res.data);
    setSchools(
      res.data.map((i) => ({
        ...i,
        label: i.title,
      }))
    );
  }, 700);

  const debouceRequest = useCallback((value: any) => request(value), []);

  const findSchool = (school: string) => {
    if (!school) return;
    debouceRequest(school);
  };

  const selectSchool = (target: any) => {
    const value = target.textContent;
    if (value === i18n.notInList && window) {
      window.open(`${window.location.origin}/schools/create`, '_blank');
      return;
    }
    const selectedSchool = schools.find((i) => i.title === value);
    setSelectedSchoolId(selectedSchool?.id);
  };

  const resetData = () => {
    if (!selectedSchoolId) getSchools();
  };

  return (
    <Template>
      {initEvent && (
        <>
          <Event
            handler={subscribeApplicationDataByScanner}
            event={`scan_${user.id}`}
          />
          <Event
            handler={subscribeApplicationData}
            event={`reader_${user.id}`}
          />
        </>
      )}
      <div className='create-application-page' id='create-application-page'>
        <div className='scanner-loader'>
          <CircularProgress className='scanner-loader-spinner' />
        </div>
        <Popup ref={userModalRef} onClick={closeHandler}>
          <UserModal
            typeError={typeError}
            user={confirmUser}
            acceptUser={acceptUser}
            cancel={closeUserModal}
          />
        </Popup>
        <Popup ref={passModalRef}>
          <PassModal
            onHide={() => passModalRef.current.onHide()}
            confirm={confirmPass}
          />
        </Popup>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          className='create-application-page__header'
        >
          <Grid item>
            <Typography variant='h3'>{i18n.createApplication}</Typography>
          </Grid>
          <Grid item>
            <Button variant='contained' color='success' onClick={clearFields}>
              {i18n.clear}
            </Button>
          </Grid>
        </Grid>
        <form
          className='create-application-page__form '
          onSubmit={createApplication}
        >
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel error={!selectedCategory && hasError}>
                  {i18n.selectCategory}
                </InputLabel>
                <Select
                  label={i18n.selectCategory}
                  value={selectedCategory}
                  error={!selectedCategory && hasError}
                  onChange={(e) => selectCategory(e.target.value)}
                >
                  {categories.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i[`title${lang}`]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!!selectedCategory && (
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel error={!vehicleType && hasError}>
                    {i18n.selectVehicleType}
                  </InputLabel>
                  <Select
                    label={i18n.selectVehicleType}
                    value={vehicleType}
                    error={!vehicleType && hasError}
                    onChange={(e) =>
                      setVehicleType(e.target.value as IVehicleType)
                    }
                  >
                    {vehicleTypes.map((i) => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!!langs.length && (
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel error={!applicationLang && hasError}>
                    {i18n.selectLanguage}
                  </InputLabel>
                  <Select
                    value={applicationLang}
                    label={i18n.selectLanguage}
                    error={!applicationLang.trim() && hasError}
                    onChange={(e) => setApplicationLang(e.target.value)}
                  >
                    {langs.map((i) => (
                      <MenuItem key={i.label} value={i.value}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                className={cn('create-application-page-by-user__select', {
                  'create-application-page-by-user__select--error':
                    hasError && !selectedSchoolId,
                })}
                onChange={(e) => selectSchool(e.target)}
                size='small'
                options={[...schools, { label: i18n.notInList }]}
                renderInput={(params) => (
                  <TextField
                    error={hasError && !selectedSchoolId}
                    {...params}
                    onBlur={resetData}
                    label={i18n.choiceSchool}
                    onChange={(e) => findSchool(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel error={!isForeigner && hasError}>
                  {i18n.isForeigner}
                </InputLabel>
                <Select
                  label={i18n.isForeigner}
                  value={isForeigner}
                  error={!isForeigner && hasError}
                  onChange={(e) => setIsForeigner(e.target.value)}
                >
                  <MenuItem value={'false'}>
                    {i18n.resident}
                  </MenuItem>
                  <MenuItem value={'true'}>
                    {i18n.noResident}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!!regions.length && (
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel error={!selectedRegion && hasError}>
                    {i18n.residencePlace}
                  </InputLabel>
                  <Select
                    label={i18n.residencePlace}
                    value={selectedRegion}
                    error={!selectedRegion && hasError}
                    onChange={(e) => setSelectedRegion(e.target.value)}
                  >
                    {regions.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`title${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              className='create-application-page__search-container'
            >
              <MaskInput
                phone={userPhone}
                setPhone={setUserPhone}
                className={cn(
                  'create-application-page__search-input',
                  'create-application-page__phone create-application-page__field',
                  {
                    'create-application-page__phone--error':
                      hasError && userPhone.length < 12,
                  }
                )}
              />
              <div
                className='create-application-page__search-button'
                onClick={searchUserByPhone}
              >
                <SearchIcon />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className='create-application-page__search-container'
            >
              <TextField
                className='create-application-page__search-input'
                value={pinfl}
                onChange={(e) => setPinfl(e.target.value)}
                label={i18n.pinfl}
                error={hasError && pinfl.length < 13}
                inputProps={{ maxLength: 14 }}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
              <div
                className='create-application-page__search-button'
                onClick={searchUserByPinfl}
              >
                <SearchIcon />
              </div>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  autoComplete='off'
                  value={serial}
                  onChange={(e) => setSerial(e.target.value.toUpperCase())}
                  variant='outlined'
                  inputProps={{ maxLength: 255 }}
                  size='small'
                  error={hasError && !serial.trim()}
                  label={i18n.passportSerial}
                />
              </Grid>
              <Grid
                item
                xs={9}
                className='create-application-page__search-container'
              >
                <TextField
                  className='create-application-page__search-input'
                  autoComplete='off'
                  inputProps={{ maxLength: 255 }}
                  value={number}
                  error={hasError && !number.trim()}
                  onChange={(e) => setNumber(e.target.value)}
                  variant='outlined'
                  size='small'
                  label={i18n.passportNumber}
                />
                <div
                  className='create-application-page__search-button'
                  onClick={checkSerialNumberPassport}
                >
                  <SearchIcon />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={expireMainDoc}
                onChange={(e) => setExpireMainDoc(e.target.value)}
                label={'Срок паспорта'}
                autoComplete='off'
                inputProps={{ maxLength: 255 }}
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                error={!expireMainDoc.trim() && hasError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={expire083}
                onChange={(e) => setExpire083(e.target.value)}
                label={'Срок мед. справки'}
                autoComplete='off'
                inputProps={{ maxLength: 255 }}
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                error={!expire083.trim() && hasError}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={2}
              className={'create-application-page__user-data'}
            >
              <Grid item xs={12}>
                <Typography variant='h4'>{i18n.userData}</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoComplete='off'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  variant='outlined'
                  inputProps={{ maxLength: 255 }}
                  size='small'
                  label={i18n.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoComplete='off'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  variant='outlined'
                  inputProps={{ maxLength: 255 }}
                  size='small'
                  label={i18n.lastName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoComplete='off'
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  variant='outlined'
                  inputProps={{ maxLength: 255 }}
                  size='small'
                  label={i18n.middleName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  label={i18n.dateOfBirth}
                  autoComplete='off'
                  inputProps={{ maxLength: 255 }}
                  variant='outlined'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {!!selectedCategory && (
                <Grid item xs={12}>
                  <Grid>
                    <Typography
                      className='create-application-page__files'
                      variant='h4'
                    >
                      {i18n.documentList}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2} alignItems='stretch'>
                    {docTypes
                      .sort((a, b) => a.type.localeCompare(b.type))
                      .map((i) => (
                        <Grid item md={3} sm={6} xs={12} key={i.id}>
                          <FileUploads
                            doc={i}
                            errorDoc={
                              errorDocs.find((ii) => ii.id === i.id)?.type
                            }
                            selectFile={selectFile}
                            key={i.id}
                            create
                            hideUpload={false}
                            certificateData={{
                              vehicleType: vehicleType,
                              pinfl: pinfl
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className='create-application-page__button-wrap'
          >
            <Grid item xs={6}>
              <Link
                className='create-application-page__button'
                to='/applications'
              >
                <Button
                  variant='contained'
                  color='inherit'
                  className='create-application-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='success'
                className='create-application-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
};
