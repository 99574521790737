import Template from "components/Template/Template"
import './CertificatesHistoryPage.sass'
import { useEffect, useRef, useState } from "react"
import { certificateServices } from "api/services"
import { Button, Modal, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material"
import { AppState } from "store/store"
import { useSelector } from "react-redux"
import { ICertificate, IResCertificate } from "api/services/certificates/types"
import { Link } from "react-router-dom"
import moment from "moment"
import Popup from "components/Popup/Popup"
import CertificateHistoryModal from "components/Popup/components/CertificateHistoryModal/CertificateHistoryModal"


const CertificatesHistoryPage: React.FC = () => {
  const [data, setData] = useState<IResCertificate[]>()
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [certificate, setCertificate] = useState<IResCertificate>()
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const updateRef = useRef<any>()

  useEffect(() => {
    getData()
  }, [page, take])
  
  useEffect(() => {
    if(!certificate) return
    updateRef.current.onShow()
  }, [certificate])

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      // id: 20
    }
    const res = await certificateServices.getCertificatesHistory(params).then((res) => res.data)
    setData(res.data)
    setTotalCount(res.count)
  }

  const openUpdateModal = (data: IResCertificate) => {
    setCertificate(data)
  }

  return (
    <Template>
      <div className="certificates-history-page">
      <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Номер сертификата</TableCell>
                <TableCell>Дата создания</TableCell>
                <TableCell>Ф.И.О</TableCell>
                <TableCell>Дата рождения</TableCell>
                <TableCell>ПИНФЛ</TableCell>
                <TableCell>Тип транспортного средства</TableCell>
                <TableCell>Годен до</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((ii) => (
                <TableRow key={ii.id}>
                  <TableCell>{ii.id}</TableCell>

                  <TableCell>
                    {`${ii?.serial}${ii?.id.toString().padStart(14, '0')}`}
                  </TableCell>
                  <TableCell>
                    {moment(ii.createdAt).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    {ii.lastName} {ii.firstName} {ii.middleName}
                  </TableCell>
                  <TableCell>
                    {moment(ii.dateOfBirth).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>{ii.application?.user.pinfl}</TableCell>
                  <TableCell>{ii.vehicleType}</TableCell>
                  <TableCell>
                    {moment(ii?.createdAt)
                      .add(1, 'years')
                      .format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => openUpdateModal(ii)}
                      variant='contained'
                      className='update-application-page__button update-application-page__button--confirm'
                      color='success'
                    >
                      История
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
      <Popup ref={updateRef} onClick={() => setCertificate(undefined)}>
        {certificate && (
          <CertificateHistoryModal data={certificate.certificateHistory}/>
        )}
      </Popup>
    </Template>
  )
}

export default CertificatesHistoryPage