import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { AppState } from 'store/store';
import { Event } from 'react-socket-io';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'store/ducks/auth/actions';
import { queueItemsServices, motordromesServices } from 'api/services';
import { QueueType } from 'api/services/queue/types';
import { IResQueueItem } from 'api/services/queueItems/types';
import { ShowNotification } from 'helpers/showNotice';
import { QueueItemList } from 'components/QueueItemList/QueueItemList';
import Confirmation from 'components/Confirmation/Confirmation';
import { Button, TextField, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import './ExamPage.sass';

export const ExamPage: React.FC = () => {
  const [queueItems, setQueueItems] = useState<IResQueueItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [queueTitle, setQueueTitle] = useState('');
  const [itemsCount, setItemsCount] = useState('');
  const [initEvent, setInitEvent] = useState(true);
  const [isPractice, setIsPractice] = useState(true);
  const [showCars, setShowCars] = useState(false);
  const [queueId, setQueueId] = useState('');
  const confirmationRef = useRef<any>();
  const dispatch = useDispatch();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.queuePage
  );
  const queueItemsRef = useRef<IResQueueItem[]>([]);
  const { user } = useSelector((state: AppState) => state.users);
  const queueRef = useRef<any>();
  const history = useNavigate();

  useEffect(() => {
    getQueue();
  }, [user?.motordromeId]);

  useEffect(() => {
    getQueueItemsByQueue();
    return () => setInitEvent(false);
  }, [lang, queueId]);

  

  const getQueue = async () => {
    if (!user?.motordromeId) return;
    const res = await motordromesServices
      .getMotordromeById(user?.motordromeId)
      .then((res) => res.data);
    queueRef.current = res.queueId;
    setQueueId(res.queueId.toString());
  };

  const getQueueItemsByQueue = async () => {
    if(!queueId) return
    const res = await queueItemsServices
      .getQueueItemsByQueue(Number(queueId))
      .then((res) => res.data);
    setQueueItems(res);
    queueItemsRef.current = res;
  };

  const closeDropdown = () => {
    setSelectedItem(undefined);
    setShowCars(false);
  };

  const selectItem = (e: React.MouseEvent, id?: number) => {
    e.stopPropagation();
    if (!id || id === selectedItem) {
      setSelectedItem(undefined);
      return;
    }
    setSelectedItem(id);
  };

  const callItem = async (
    id: number,
    active: boolean = false,
    car?: number
  ) => {
    if (isPractice && active) {
      try {
        await queueItemsServices.updateQueueItem(id, {
          isActive: active,
          logosCarId: car,
        });
        closeDropdown();
      } catch {}
      return;
    }
    try {
      await queueItemsServices.updateQueueItem(id, { isActive: active });
    } catch {}
  };

  const bulkItems = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!itemsCount) return;
    if (!queueItems.length) return ShowNotification(i18n.clearedQueue);
    try {
      // await queueItemsServices.bulkQueueItems({
      // 	queueId: Number(queueId),
      // 	count: Number(itemsCount),
      // });
    } finally {
      setItemsCount('');
    }
  };

  const subscribeCreateQueueItem = async (data: IResQueueItem) => {
    const newArr = [...queueItemsRef.current, data];
    setQueueItems(newArr);
    queueItemsRef.current = newArr;
  };

  const subscibeUpdateQueueItem = (data: IResQueueItem) => {
    const newArr = [
      ...queueItemsRef.current.filter((i) => i.id !== data.id),
      data,
    ];
    setQueueItems(newArr);
    queueItemsRef.current = newArr;
  };

  const subscribeDeleteQueueItem = (data: IResQueueItem) => {
    const newArr = queueItemsRef.current.filter((i) => i.id !== data.id);
    setQueueItems(newArr);
    queueItemsRef.current = newArr;
  };

  const subscribeDeleteQueueItems = () => {
    if (!!queueItemsRef.current.length) {
      ShowNotification(i18n.clearedQueue);
      setQueueItems([]);
      queueItemsRef.current = [];
    }
  };

  const deleteQueueItem = async (id: number) => {
    await queueItemsServices.deleteQueueItem(id);
  };

  const clearItems = async () => {
    if (!queueItems.length) {
      ShowNotification(i18n.emptyQueue);
      return;
    }
    await queueItemsServices.deleteQueueItemByQueueId(Number(queueId));
  };

  const logout = () => {
    dispatch(authActions.signOut());
    history('/auth/sign-in');
  };
  return (
    <div className='manager-queue-page' onClick={closeDropdown}>
      <>
        {initEvent && !!queueId && (
          <>
            <Event
              handler={subscribeCreateQueueItem}
              event={`create_queue_${queueId}`}
            />
            <Event
              handler={subscibeUpdateQueueItem}
              event={`update_queue_${queueId}`}
            />
            <Event
              handler={subscribeDeleteQueueItem}
              event={`delete_queue_${queueId}`}
            />
            <Event
              handler={subscribeDeleteQueueItems}
              event={`clear_queue_${queueId}`}
            />
          </>
        )}

        <Confirmation
          ref={confirmationRef}
          submit={logout}
          title='Вы действительно хотите выйти?'
        />
        <div className='manager-queue-page__header'>
          <Typography variant='h3' className='manager-queue-page__title'>
            {queueTitle}
          </Typography>
          <form className='manager-queue-page__action' onSubmit={bulkItems}>
            {!isPractice && (
              <>
                <Button
                  variant='contained'
                  onClick={clearItems}
                  className='manager-queue-page__action-clear'
                >
                  {i18n.clear}
                </Button>
                <TextField
                  variant='outlined'
                  label={i18n.enterCount}
                  value={itemsCount}
                  type='number'
                  className='manager-queue-page__action-input'
                  onChange={(e) => setItemsCount(e.target.value)}
                  autoComplete='off'
                />
                <Button
                  variant='contained'
                  type='submit'
                  className='manager-queue-page__action-button'
                >
                  {i18n.power}
                </Button>
              </>
            )}
            <LogoutIcon
              onClick={() => confirmationRef.current.onShow()}
              className='manager-queue-page__action-logout'
              sx={{ fontSize: 40, color: '#4ACBCE' }}
            />
          </form>
        </div>
        <QueueItemList
          queueItems={queueItems?.filter(
            (i) => i.application.vehicleType === 'A'
          )}
          selectedItem={selectedItem}
          callItem={callItem}
          deleteQueueItem={deleteQueueItem}
          selectItem={selectItem}
          isPractice={isPractice}
          showCars={showCars}
          setShowCars={setShowCars}
        />
      </>
    </div>
  );
};
