import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import WebIcon from '@mui/icons-material/Web';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaymentIcon from '@mui/icons-material/Payment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import FlipTwoToneIcon from '@mui/icons-material/FlipTwoTone';
import AssessmentIcon from '@mui/icons-material/Assessment';
import * as sidebarActions from 'store/ducks/sidebar/actions';
import { ALL_USER_ROLES } from 'constants/roles';
import navigation, { RouteType } from 'routes/navigation';
import './Sidebar.sass';

interface INav {
  path?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  title: string;
  role: IRole[];
  children?: INav[];
}

const Sidebar: React.FC = () => {
  const lang = useSelector((state: AppState) => state.i18n.key);
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useState<INav[]>([]);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.components.Sidebar
  );
  const dispatch = useDispatch();
  const { isHidden } = useSelector((state: AppState) => state.sidebar);
  const { user } = useSelector((state: AppState) => state.users);
  const {
    SU,
    RECEPTION,
    SCANNER,
    CASHIER,
    EXAM,
    USER,
    MOTORDROME,
    SECURITY,
    QUEUE,
    GAI,
    QUEUE_MANAGER,
    UMCH,
    REPORT,
    HEAD_ACCOUNTANT,
    ACCOUNTANT,
    APPEAL,
    UZAKOVA,
    BRANCH_HEAD,
    ASSISTANT_BRANCH_HEAD,
    SATURDAY_EDITOR,
    HEAD,
    HEAD_OF_IT,
  } = ALL_USER_ROLES;

  useEffect(() => {
    const child = navigation
      .filter((i) => i.types && i.roles?.some((ii) => ii === user?.role))
      .map((ii) => ({ ...ii, icon: undefined, title: i18n?.[`${ii.title}`] }));
    const mainNav = navigation
      .filter(
        (i) => i.roles?.some((ii) => ii === user?.role) && !i.types && i.title
      )
      ?.map((ii) => ({
        ...ii,
        title: i18n?.[`${ii.title}`],
      }));

    setNav(
      [
        {
          title: i18n.cash,
          icon: PaymentsIcon,
          role: [SU, CASHIER],
          children: child.filter((i) =>
            i.types?.some((ii) => ii === RouteType.CASH_REG)
          ),
        },
        {
          title: i18n.accounting,
          icon: PaymentIcon,
          role: [SU, ACCOUNTANT, HEAD_ACCOUNTANT],
          children: child.filter((i) =>
            i.types?.some((ii) => ii === RouteType.ACOUNTING)
          ),
        },
        {
          title: i18n.security,
          icon: FlipTwoToneIcon,
          role: [SU, SECURITY],
          children: child.filter((i) =>
            i.types?.some((ii) => ii === RouteType.SECURITY)
          ),
        },
        {
          title: i18n.reception,
          icon: WebIcon,
          role: [
            SU,
            RECEPTION,
            SCANNER,
            REPORT,
            UZAKOVA,
            BRANCH_HEAD,
            HEAD,
            ASSISTANT_BRANCH_HEAD,
            SATURDAY_EDITOR,
            HEAD_OF_IT,
          ],
          children: child.filter((i) =>
            i.types?.some((ii) => ii === RouteType.RECEPTION)
          ),
        },
        {
          title: i18n.statistics,
          icon: SummarizeIcon,
          role: [SU, REPORT, UZAKOVA, BRANCH_HEAD, ASSISTANT_BRANCH_HEAD, HEAD],
          children: child.filter((i) =>
            i.types?.some((ii) => ii === RouteType.STATS)
          ),
        },
        {
          title: 'UMCH',
          icon: AssessmentIcon,
          role: [SU, UMCH],
          children: child.filter((i) =>
            i.types?.some((ii) => ii === RouteType.UMCH)
          ),
        },
        {
          title: i18n.appeals,
          icon: HistoryToggleOffOutlinedIcon,
          role: [SU, APPEAL],
          children: child.filter((i) =>
            i.types?.some((ii) => ii === RouteType.APPEAL)
          ),
        },
        ...(mainNav as any),
      ].filter((i) => i.role?.some((ii: any) => ii === user?.role) || !i.role)
    );
  }, [lang]);

  const changeHandler = () => {
    dispatch(sidebarActions.handlelSidebar(!isHidden));
  };

  return (
    <aside
      className={cn('sidebar', {
        'sidebar--active': open,
      })}
    >
      <div className='sidebar__toggle-wrap' onClick={() => setOpen(true)}>
        <FormatAlignRightIcon
          className='sidebar__toggle'
          sx={{ fontSize: 30, color: '#000' }}
        />
      </div>
      <div className='sidebar__wrap' onClick={() => setOpen(false)}>
        <div className='sidebar__in' onClick={(e) => e.stopPropagation()}>
          <div className='sidebar__close-wrap'>
            <div className='sidebar__close' onClick={() => setOpen(false)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div
            className='sidebar__link sidebar__hamburger'
            onClick={changeHandler}
          >
            <MenuOpenIcon sx={{ fontSize: 30, color: '#fff' }} />
          </div>
          {nav?.map((i, index) =>
            i.path ? (
              <Link className='sidebar__link' to={i?.path || ''} key={index}>
                {i.icon && (
                  <i.icon
                    className='sidebar__icon'
                    sx={{ fontSize: 30, color: '#fff' }}
                  />
                )}
                <span className='sidebar__text'>{i.title}</span>
                {!!i?.children?.length && (
                  <div className='sidebar__inner'>
                    {i?.children?.map((ii: INav) => (
                      <Link
                        className='sidebar__link sidebar__link--inner'
                        to={ii?.path || ''}
                        key={ii.path}
                      >
                        {ii.icon && (
                          <ii.icon
                            className='sidebar__icon'
                            sx={{ fontSize: 30, color: '#fff' }}
                          />
                        )}
                        <span className='sidebar__text'>{ii.title}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </Link>
            ) : (
              <div className='sidebar__link' key={index}>
                {i.icon && (
                  <i.icon
                    className='sidebar__icon'
                    sx={{ fontSize: 30, color: '#fff' }}
                  />
                )}
                <span className='sidebar__text'>{i.title}</span>
                {!!i?.children?.length && (
                  <div className='sidebar__inner'>
                    {i?.children?.map((ii: INav) => (
                      <Link
                        className='sidebar__link sidebar__link--inner'
                        to={ii?.path || ''}
                        key={ii.path}
                      >
                        {ii.icon && (
                          <ii.icon
                            className='sidebar__icon'
                            sx={{ fontSize: 30, color: '#fff' }}
                          />
                        )}
                        <span className='sidebar__text'>{ii.title}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
