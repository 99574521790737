import { ISelectedDoc } from 'api/services/docs/types';
import { FileUploader } from 'components/FileUploader/FileUploader';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import Passport from 'assets/images/passport-icon.svg';
import { isSuRole, isUserRole } from 'helpers/checkRoles';

interface Props {
  doc: ISelectedDoc;
  selectFile(src: string, id: number): void;
  hideUpload: boolean;
  create?: boolean;
  errorDoc?: string;
  disabled?: boolean;
  certificateData?: {
    vehicleType?: string,
    pinfl: string
  }
}

export const FileUploads: React.FC<Props> = ({
  doc,
  selectFile,
  create = false,
  hideUpload,
  errorDoc,
  disabled,
  certificateData
}) => {
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.components.FileUploads
  );
  const { user } = useSelector((state: AppState) => state.users);

  switch (doc.type) {
    case 'PHOTO':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.photo}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'MAIN_DOC':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name='Паспорт'
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'MAIN_DOC_REGISTRATION':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.passport}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'PINFL_FOR_FOREIGNER':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={'ПИНФЛ для иностранного гр.'}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'DOCUMENT_CONFIRMING':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={'Документ утверждающий до 12 месяцев водил ТС'}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'EMPLOYER_REFERRAL':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.employerReferral}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'REPLACEMENT_DRIVERS_LICENSE':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.replacement}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'NATIONAL_DRIVERS_LICENSE_1':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.driverLicence_1}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'NATIONAL_DRIVERS_LICENSE_2':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.driverLicence_2}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'DIRECTION_OF_AUTHORIZED':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.direction}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'MAIN_MEDICAL_CARTIFICATE_1':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.honey083_1}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'MAIN_MEDICAL_CARTIFICATE_2':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.honey083_2}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'DIPLOMA_ANNEX':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.diplomaAnnex}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'NATIONAL_DRIVERS_LICENSE_ALL':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.nationalDriversLicence}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'UNIFORM_CERTIFICATE':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name={i18n.certificateSingleSample}
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
          certificateData={certificateData}
        />
      );
    case 'APPLICATION_APPEAL':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name='Заявление на апелляцию'
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'COMMISSION_DECISION':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name='Решение апелляционной комиссии'
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    case 'EXTRACT_FROM_WORK_BOOK':
      return (
        <FileUploader
          icon={Passport}
          type={doc.type}
          name='Для работающих водителей — выписка из трудовой книжки или справка (выписка из приказа) с указанием вида закрепленного за ним автомототранспортного средства;'
          src={doc.src}
          selectFile={selectFile}
          id={doc.id}
          create={create}
          hideUpload={hideUpload}
          errorDoc={errorDoc}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};
