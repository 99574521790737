import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import {
  applicationsServices,
  eavtotalimServices,
  statusHistoryServices,
} from 'api/services';
import { IResApplicationWithDocs } from 'api/services/applications/types';
import { ISelectedDoc } from 'api/services/docs/types';
import STATUS_TYPES from 'references/status-types';
import Template from 'components/Template/Template';
import Popup from 'components/Popup/Popup';
import { ResultsModal } from 'components/Popup/components/ResultsModal/ResultsModal';
import { IResStatusHistory } from 'api/services/status-history/types';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import './ApplicationHistoryPage.sass';
import * as serviceActions from 'store/ducks/services/actions';
import AppealModal from 'components/Popup/components/AppealModal/AppealModal';
import { isHeadOfIT, isSuRole, isUserRole } from 'helpers/checkRoles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { IResEavtotalim } from 'api/services/eavtotalim/types';
import { ShowNotification } from 'helpers/showNotice';
import { NoticeStatus } from 'store/ducks/notice/types';

export const ApplicationHistoryPage: React.FC = () => {
  const [application, setApplication] = useState<IResApplicationWithDocs>();
  const [examInfo, setExamInfo] = useState<{
    status: IStatusType;
    history: IResStatusHistory[];
  }>();
  const [appealDoc, setAppealDoc] = useState<ISelectedDoc[]>();
  const [eavtotalimInfo, setEavtotalimInfo] = useState<{
    info: IResEavtotalim[];
    app?: IResApplicationWithDocs;
  }>();
  const [loading, setLoading] = useState(false);

  const { applicationId } = useParams<{ applicationId: string }>();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.ApplicationHistoryPage
  );
  const { branches } = useSelector((state: AppState) => state.services);
  const user = useSelector((state: AppState) => state.users.user);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const ref = useRef<any>();
  const appealRef = useRef<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    getBranches();
  }, []);

  const getData = async () => {
    const applicationRes = await applicationsServices
      .getApplicationById(Number(applicationId))
      .then((res) => res.data);
    setApplication(applicationRes);
  };

  const getBranches = async () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const showModalInfo = (status: IStatusType, history: IResStatusHistory) => {
    if (status === 'T_EXAM' || status === 'P_EXAM') {
      setExamInfo({ status: status, history: [history] });
      ref.current.onShow();
    }
  };

  const showAppealModal = (data?: ISelectedDoc[]) => {
    appealRef.current.onShow();
    setAppealDoc(data);
  };

  const closeAppealModal = () => {
    setAppealDoc(undefined);
  };
  const closeHandler = () => {
    appealRef.current.onHide();
    setAppealDoc(undefined);
  };

  const changeStatusHistory = async (id: number, blocked: boolean) => {
    if (loading) return;
    setLoading(true);
    try {
      await statusHistoryServices.blockStatusHistory(id, blocked);
      ShowNotification('Заблокировано успешно', NoticeStatus.INFO);
      getData();
    } catch {
      ShowNotification('Ошибка, попробуйте позже');
    } finally {
      setLoading(false);
    }
  };

  const retrySend = async (id?: number) => {
    if (!id || loading) return;
    setLoading(true);
    try {
      await eavtotalimServices.retryEavtotalimItem(id);
      ShowNotification('Отправлено', NoticeStatus.SUCCESS);
      getData();
    } catch {
      ShowNotification('Ошибка, попробуйте позже');
    } finally {
      setLoading(false);
    }
  };

  // const activateStatusHistory = async (id?: number) => {
  //   if (!id || loading) return;
  //   setLoading(true);
  //   try {
  //     await eavtotalimServices.activateEavtotalimItem(id);
  //     ShowNotification('Разблокировано успешно', NoticeStatus.SUCCESS);
  //     getData();
  //   } catch {
  //     ShowNotification('Ошибка, попробуйте позже');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <Template>
      <div className='application-history-page'>
        <div className='application-history-page__row'>
          <Typography variant='h3'>{i18n.applicationHistory}</Typography>
        </div>
        <Paper className='application-history-page__paper'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{i18n.branch}</TableCell>
                <TableCell>{i18n.oldStatus}</TableCell>
                <TableCell>{i18n.nextStatus}</TableCell>
                <TableCell>{i18n.createdDate}</TableCell>
                <TableCell>Дата отправки в гаи</TableCell>
                <TableCell>Дата последней отправки</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Сообщение</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {application?.statusHistory
                ?.sort(
                  (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt)
                )
                .map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{i.id}</TableCell>
                    <TableCell>
                      {
                        branches?.find((ii) => ii.id === i.branchId)?.[
                          `name${lang}`
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      {
                        STATUS_TYPES.find((ii) => i.oldStatus === ii.value)
                          ?.label
                      }
                    </TableCell>
                    <TableCell>
                      {
                        STATUS_TYPES.find((ii) => i.newStatus === ii.value)
                          ?.label
                      }
                    </TableCell>
                    <TableCell>
                      {moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      {i.eavtotalim &&
                        moment(i.eavtotalim?.createdAt).format(
                          'DD.MM.YYYY HH:mm'
                        )}
                    </TableCell>
                    <TableCell>
                      {i.eavtotalim &&
                        moment(i.eavtotalim?.updatedAt).format(
                          'DD.MM.YYYY HH:mm'
                        )}
                    </TableCell>
                    {!isUserRole(user) && (
                      <>
                        <TableCell>
                          {i.eavtotalim &&
                            (i.eavtotalim?.responseBody?.status === 1 ? (
                              <CheckCircleIcon className='application-history-page__success' />
                            ) : (
                              <DoNotDisturbOnIcon className='application-history-page__error' />
                            ))}
                        </TableCell>
                        <TableCell>
                          {i.eavtotalim?.responseBody?.message}
                        </TableCell>
                      </>
                    )}

                    <TableCell>
                      <div className='application-history-page__row'>
                        {i.result && (
                          <Button
                            variant='contained'
                            color='success'
                            className='application-history-page__button'
                            size='small'
                            onClick={() => showModalInfo(i.oldStatus, i)}
                          >
                            {i18n.more}
                          </Button>
                        )}
                        {i.appealDocs && (
                          <Button
                            variant='contained'
                            color='error'
                            className='application-history-page__button'
                            size='small'
                            onClick={() => showAppealModal(i.appealDocs)}
                          >
                            Апелляция
                          </Button>
                        )}
                        {(isHeadOfIT(user) || isSuRole(user)) && i.result && (
                          <>
                            {i.eavtotalim &&
                              i.eavtotalim?.responseStatus !== 200 && (
                                <Button
                                  variant='contained'
                                  color='info'
                                  size='small'
                                  className='application-history-page__button'
                                  onClick={() => retrySend(i.eavtotalim?.id)}
                                >
                                  Переотправить
                                </Button>
                              )}
                            {!i.eavtotalim && !i.blocked && (
                              <Button
                                variant='contained'
                                color='info'
                                size='small'
                                className='application-history-page__button'
                                onClick={() => changeStatusHistory(i.id, true)}
                              >
                                Заблокировать
                              </Button>
                            )}
                            {i.blocked && (
                              <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                className='application-history-page__button'
                                onClick={() => changeStatusHistory(i.id, false)}
                              >
                                Разблокировать
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <Popup ref={appealRef} onClick={closeAppealModal}>
        <AppealModal onHide={closeHandler} data={appealDoc} />
      </Popup>
      <Popup ref={ref}>
        {application && (
          <ResultsModal
            results={examInfo?.history}
            type={examInfo?.status}
            app={application}
          />
        )}
      </Popup>
    </Template>
  );
};
