import { Grid, Typography } from '@mui/material';
import { eavtotalimServices } from 'api/services';
import { IResEavtotalim } from 'api/services/eavtotalim/types';
import Template from 'components/Template/Template';
import { useState } from 'react';
import { useParams } from 'react-router';

export const EavtotalimItemPage = () => {
  const [eavtotalimItem, setEavtotalimItem] = useState<IResEavtotalim>();

  const { id } = useParams<{ id: string }>();
  const getEavtotalimItem = async () => {};

  if (!eavtotalimItem) return null;
  return (
    <Template>
      <Grid xs={6} item>
        <Typography variant='h3'>{`Заявка №${id}`}</Typography>
      </Grid>
      <div className='eavtotalim-item-page'>
        <ul className='eavtotalim-item-page__info'>
          <li>
            <span className='eavtotalim-item-page__label'></span>
            <span className='eavtotalim-item-page__value'></span>
          </li>
        </ul>
      </div>
    </Template>
  );
};
