import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"
import Template from "components/Template/Template"
import * as serviceActions from 'store/ducks/services/actions';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store/store";
import './StatisticsPage.sass'
import FileSaver from 'file-saver';
import { generalReportsServices } from "api/services";
import { ShowNotification } from "helpers/showNotice";
import { IResReportStatistic } from "api/services/general-reports/types";
import cloneDeep from "lodash/cloneDeep";


const StatisticsPage: React.FC = () => {
  const [data, setData] = useState<IResReportStatistic[]>()
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [branchId, setBranchId] = useState<number>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { user } = useSelector((state: AppState) => state.users);
  const { branches } = useSelector((state: AppState) => state.services);
  const [averageAttemptsVal, setAverageAttemptsVal] = useState<number[]>([])
  const [averageCheckVal, setAverageCheckVal] = useState<number[]>([])
  const dispatch = useDispatch();

  useEffect(() => {
    getBranches();
  }, []);

  const getBranches = () => {
    if (!branches?.length) dispatch(serviceActions.getBranches());
  };


  const exportReport = async () => {
    if(!startDate.trim() || !endDate.trim()){
      ShowNotification('Укажите дату')
      return
    }
    const params = {
      startDate,
      endDate,
      branchId
    }
    try {
      setLoading(true)
      const res = await generalReportsServices.exportGeneralReportStatistics(params).then((res) => res.data)
      const blob = new Blob(['\ufeff', res], { type: 'text/csv' });
      FileSaver.saveAs(blob, 'statistic.csv');
    } finally {
      setLoading(false)
    }
  }

  const getData = async () => {
    if(!startDate.trim() || !endDate.trim()){
      ShowNotification('Укажите дату')
      return
    }
    const params = {
      startDate,
      endDate,
      branchId
    }
    const res = await generalReportsServices.getGeneralReportStatistics(params).then((res) => res.data)
    let arr = [] as number[]
    res.forEach((i) => {
      if(!i.created || !i.total) return
      arr.push(Number(Number(i.total) / Number(i.created)))
    })
    let arr2 = [] as number[]
    arr.forEach((i) => {
      if(!i) return
      arr2.push(i * 550000)
    })
    setAverageAttemptsVal(arr)
    setAverageCheckVal(arr2)
    setData(res)
  }


  const clearHandler = () => {
    setStartDate('');
    setEndDate('');
  };

  const totalSum = (arr: any) => {
    if (arr?.length === 0) return 0
    return arr
      ?.map((i: any) => Number(i))
      ?.reduce((sum: number, current: number) => sum + current)
  }

  return (
    <Template>
      <div className="statistics-report-page">
        <Typography variant='h3' className='statistics-report-page__title'>
          Общий отчет
        </Typography>
        <div className='general-reports__form'>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item md={2.4}>
              <TextField
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label='С'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                error={hasError && !startDate.trim()}
              />
            </Grid>
            <Grid item md={2.4}>
              <TextField
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label='По'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                error={hasError && !endDate.trim()}
              />
            </Grid>
            {!!branches.length && (
              <Grid item md={2.4}>
                <FormControl
                  fullWidth
                  className='select-small'
                  error={!branchId && hasError}
                >
                  <InputLabel variant='outlined' id='creationType'>
                    Филиал
                  </InputLabel>
                  <Select
                    labelId='branch'
                    label='Филиал'
                    onChange={(e) => setBranchId(e.target.value as number)}
                    value={branchId || ''}
                    size='small'
                  >
                    <MenuItem value={undefined}>
                      Нет
                    </MenuItem>
                    {branches?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={1.6}>
              <Button
                variant='contained'
                color='success'
                className='reports-for-school__button'
                onClick={exportReport}
                disabled={loading}
              >
                Экспорт
              </Button>
            </Grid>
            <Grid item md={1.6}>
              <Button
                variant='contained'
                color='success'
                className='reports-for-school__button'
                onClick={getData}
                disabled={loading}
              >
                Показать
              </Button>
            </Grid>
            <Grid item md={1.6}>
              <Button
                variant='contained'
                color='error'
                className='reports-for-school__button'
                onClick={clearHandler}
              >
                ОЧИСТИТЬ
              </Button>
            </Grid>
          </Grid>
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Наименование экзаменационного этапа</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.name_ru}</TableCell>
                ))}
                <TableCell>Всего</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Количество новых кандидатов:</TableCell>
                {data?.map((ii) => (
                  <TableCell key={ii.id}>{ii.created}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.created))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Прошли теоретический экзамен (с первой попытки+повторные):</TableCell>
                {data?.map((ii) => (
                  <TableCell key={ii.id}>{ii.t_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.t_count))}</TableCell>

              </TableRow>
              <TableRow>
                <TableCell>Сдали:</TableCell>
                {data?.map((ii) => (
                  <TableCell key={ii.id}>{ii.t_success_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.t_success_count))}</TableCell>


              </TableRow>
              <TableRow>
                <TableCell>Прошли практический экзамен (с первой попытки+повторные):</TableCell>
                {data?.map((ii) => (
                  <TableCell key={ii.id}>{ii.p_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.p_count))}</TableCell>


              </TableRow>
              <TableRow>
                <TableCell>Сдали:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.p_success_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.p_success_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Прошли теоретический экзамен с первой попытки:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.t_first_time_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.t_first_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Сдали:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.t_success_first_time_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.t_success_first_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Прошли практический экзамен с первой попытки:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.p_first_time_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.p_first_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Сдали:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.p_success_first_time_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.p_second_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Прошли теоретический экзамен повторно:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.t_second_time_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.t_second_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Сдали:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.t_success_second_time_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.t_success_second_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Прошли практический экзамен повторно:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.p_second_time_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.p_second_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Сдали:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.p_success_second_time_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.p_success_second_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Сертификаты:</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.p_success_count}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.p_second_time_count))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Общее количество ТЭ и ПЭ</TableCell>
                {data?.map((i) => (
                  <TableCell key={i.id}>{i.total}</TableCell>
                ))}
                <TableCell>{totalSum(data?.map((ii) => ii.total))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Среднее кол-во попыток на человека</TableCell>
                {averageAttemptsVal?.map((i, ind) => <TableCell key={ind}>{i.toFixed(1)}</TableCell>)}
                <TableCell>{!!averageAttemptsVal?.length && (totalSum(averageAttemptsVal) / averageAttemptsVal.length).toFixed(2) }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Средний чек на человека</TableCell>
                {averageCheckVal.map((i, ind) => (
                  <TableCell key={ind}>{i.toFixed(2)}</TableCell>
                ))}
                <TableCell>{!!averageCheckVal?.length && (totalSum(averageCheckVal) / averageCheckVal.length).toFixed(2) || ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  )
}

export default StatisticsPage