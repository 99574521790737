import { IData } from './types';

const data: IData = {
  ru: {
    general: {
      instance: {
        noAccess: 'Нет доступа',
        login: 'Пройдите авторизацию',
        uploadDocuments: 'Загрузите документы',
      },
    },
    components: {
      Header: {
        prompt: 'Вы действительно хотите выйти?',
      },
      transactionModal: {
        replenished: 'Пополнение',
        writeOff: 'Списание',
        actionType: 'Действие',
        pay: 'Оплата',
        payme: 'Payme',
        click: 'Click',
        terminal: 'Терминал',
        cash: 'Наличные',
        title: 'Транзакции',
        transactionType: 'Тип',
        amount: 'Сумма',
        status: 'Статус',
        payDate: 'Дата оплаты',
        completed: 'Успешно',
        failed: 'Ошибка оплаты',
      },
      resultsModal: {
        seeApp: 'Посмотреть заявку',
      },
      newApplicationNotice: {
        newNotice: 'Новая онлайн заявка',
        open: 'Просмотреть',
        close: 'Закрыть',
      },
      passModal: {
        enterPassNumber: 'Введите номер пропуска',
        save: 'Сохранить',
      },
      pagination: {
        rowsPerPage: 'Количество на странице',
      },
      queueList: {
        car: 'Автомобиль',
        classRoom: 'Аудитория',
        choiceCar: 'Выберите авто',
        call: 'Вызвать',
        cancel: 'Отменить',
        delete: 'Удалить',
        category: 'Категория',
      },
      //fix
      TimeLine: {
        tests: 'Тесты',
        right: 'Правильно',
        wrong: 'Не правильно',
        total: 'Итого баллов',
        startDate: 'Дата начала',
        attempts: 'Попытки',
        carNumber: 'Номер машины',
        penaltyPoints: 'Штрафные баллы',
      },
      Sidebar: {
        reports: 'Протоколы',
        schools: 'Школы',
        enterName: 'Введите название для поиска',
        control: 'Управление очередью',
        classRooms: 'Кабинеты',
        cars: 'Автомобили',
        queue: 'Очередь',
        users: 'Пользователи',
        categories: 'Категории',
        docs: 'Документы',
        applications: 'Заявки',
        statuses: 'Статусы',
        practiceCategory: 'Типы транспортных средств',
        payment: 'Оплата',
        passedExam: 'Прошедшие экзамен',
        cashPayment: 'Оплата наличными',
        userId: 'Идентификация пользователя',
        visitLogs: 'История посещений',
        motordromes: 'Автодром',
        errors: 'Ошибки',
        transactions: 'Транзакции',
        verrifyApps: 'Верифицированные заявки',
        generatReport: 'Общий отчет',
        generalStatistics: 'Статистика отчет',
        schoolStatistics: 'Статистика по школам',
        gaiSchoolsReport: 'Отчет ГАИ по школам',
        sumarry: 'Cводка',
        appeals: 'Апелляция',
        report: 'Рапорты',
        vising: 'Визинг',
        requestForChangeData: 'Запросы на изм. данных',
        certificates: 'Сертификаты',
        certificatesHistory: 'История сертификата',
        calendar: 'Календарь',
        fines: 'Штрафы',
        reception: 'Ресепшн',
        security: 'Охрана',
        accounting: 'Бухгалтерия',
        cash: 'Касса',
        statistics: 'Статистика',
        integrationWithGai: 'Интеграция с гаи',
      },
      CategoryStatuses: {
        statusDeleted: 'Статус удален',
        erorrTryAgain: 'Ошибка попробуйте еще раз',
        wantToRemove: 'Вы действительно хотите удалить статус категории?',
        add: 'Добавить статус категории',
        status: 'Статус',
        successStatus: 'Статус в случае успеха',
        failureStatus: 'Статус в случае провала',
        order: 'Порядок',
        start: 'Начат',
        finished: 'Окончен',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
      Confirmation: {
        cancel: 'Отмена',
        success: 'Подтвердить',
      },
      FileUploader: {
        fileSize: 'Слишком большой размер файла',
        selectDocument: 'Выбрать документ',
        selectAnotherDocument: 'Выбрать другой документ',
        scanning: 'Сканировать',
      },
      FileUploads: {
        mainPassport: 'Паспорт',
        photo: 'Фотография',
        passport: 'Паспорт/ID карта (с пропиской)',
        employerReferral: 'Направление работодателя',
        replacement:
          'Замена водительского удостоверения лиц единого образца или водительского удостоверения кагегорий "В" и "С " на срок более 10 лет непрерывной трудовой деятельности водителя категориями "ВЕ" и "СЕ" на основании выписки из трудовой книжки водителя',
        driverLicence_1: 'Национальные водительские права-1',
        driverLicence_2: 'Национальные водительские права-2',
        direction: 'Направление уполномоченного органа внутренних дел',
        honey083_1: 'Мед справка 083-1',
        honey083_2: 'Мед справка 083-2',
        diplomaAnnex:
          'Диплом и приложение к нему об усвоении предметов по плану',
        nationalDriversLicence:
          'Национальные водительские удостоверение ("C", "B" и "C", "D", "C" и "D", "СE" и (или) "DE")',
        certificateSingleSample: 'Свидетельство единого образца',
      },
      Footer: {
        address: 'г. Ташкент, Янгихаётский район, ул. Файзли 87',
        copyRight: '2022 Все права защищены',
      },
      CreateCategoryStatuses: {
        fillFields: 'Заполните поля',
        createdSuccess: 'Статус категории создан успешно',
        alreadyExistsDefault:
          'Статус категории со статусом "по умолчанию" уже существует',
        alreadyExistsFinish:
          'Статус категории со статусом "окончен" уже существует',
        errorTryAgain: 'Ошибка попробуйте позже',
        createStatusCategory: 'Создать статус для категории',
        selectStatus: 'Выберите статус',
        successStatus: 'Статус в случае успеха',
        failureStatus: 'Статус в случае провала',
        workDays: 'Кол-во рабочих дней до повторной попытки',
        order: 'Порядок',
        defaultStatus: 'Статус «по умолчанию»',
        finished: 'Окончен',
        cancel: 'Отменить',
        save: 'Сохранить',
      },
      UpdateCategoryStatuses: {
        fillFields: 'Заполните поля',
        updateSuccess: 'Статус категории изменен',
        errorTryAgain: 'Ошибка попробуйте позже',
        updateStatusCategory: 'Изменить статус для категории',
        selectStatus: 'Выберите статус',
        successStatus: 'Статус в случае успеха',
        failureStatus: 'Статус в случае провала',
        cost: 'Цена',
        workDays: 'Кол-во рабочих дней до повторной попытки',
        order: 'Порядок',
        defaultStatus: 'Статус «по умолчанию»',
        finished: 'Окончен',
        cancel: 'Отменить',
        save: 'Сохранить',
        alreadyExistsDefault:
          'Статус категории со статусом "по умолчанию" уже существует',
        alreadyExistsFinish:
          'Статус категории со статусом "окончен" уже существует',
      },
      TokenModal: {
        copy: 'Cкопировано в буфер обмена',
        refresh: 'Обновить',
      },
      UserModal: {
        userInfo: 'Информация о пользователе',
        FIO: 'ФИО',
        dateOfBirth: 'Дата рождения',
        passport: 'Серия/номер паспорта',
        pinfl: 'ПИНФЛ',
        phone: 'Номер телефона',
        cancel: 'Отменить',
        successUser: 'Подтвертить пользователя',
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
    },
    pages: {
      ReportPage: {
        //fix
        reportHistory: 'История протокола',
        FIO: 'ФИО',
        category: 'Категория',
        school: 'Школа',
        theoryAttempt: 'Попытки теория',
        theoryScore: 'Балы теория',
        practiceAttempt: 'Поптыки практика',
        practiceScore: 'Сдал/Не сдал',
        passed: 'Сдал',
        notPassed: 'Не сдал',
        certificateNumber: 'Номер сертификата',
        print: 'Распечатать',
      },
      ReportsPage: {
        enterDate: 'Введите дату',
        count: 'Количество',
        actions: 'Действие',
        createdAt: 'Дата создания',
        dayNumber: 'Число генерации протокола',
        reports: 'Протоколы',
        generation: 'Сгенерировать',
        name: 'Название',
        date: 'Дата',
      },
      SummaryPage: {
        branch: 'Филиал',
        from: 'С',
        to: 'По',
        branches: 'Филиалы',
        summary: 'Сводка',
        application: 'Заявка',
        clear: 'Очистить',
        search: 'Найти',
        fio: 'Ф.И.О',
        appCode: 'Код заявки',
        status: 'Статус',
        createdAt: 'Дата создания',
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        categories: 'Категория',
        vehicleTypes: 'Тип транспортного средства',
        oldStatus: 'Предыдущий статус',
        newStatus: 'Новый статус',
        attempt: 'Попытка',
        attempts: 'Попытки',
        firstAttempt: 'Первая попытка',
        retake: 'Пересдача',
        passport: 'Серия/Номер паспорта',
        phone: 'Телефон',
        result: 'Результат',
        true: 'Успешно',
        false: 'Не успешно',
      },
      UpdateClassRoomPage: {
        choiceBranch: 'Выберите филиал',
        nameError: 'Название кабинета уже существует',
        fillFields: 'Заполните поля',
        updatedClassRoom: 'Кабинет изменен',
        updateClassRoom: 'Изменить кабинет',
        enterName: 'Введите название',
        back: 'Назад',
        save: 'Сохранить',
      },
      CreateClassRoomPage: {
        choiceBranch: 'Выберите филиал',
        nameError: 'Название кабинета уже существует',
        fillFields: 'Заполните поля',
        createdClassRoom: 'Кабинет создан',
        createClassRoom: 'Создать кабинет',
        name: 'Название',
        back: 'Назад',
        save: 'Сохранить',
      },
      ClassRoomsPage: {
        classRooms: 'Кабинеты',
        deleted: 'Кабинет удален',
        wantDeleteClassRoom: 'Вы действительно хотите удалить этот кабинет?',
        updatedDate: 'Дата изменения',
        actions: 'Действия',
        name: 'Название',
        classRoom: 'Кабинет',
      },
      ApplicationForGai: {
        fio: 'Ф.И.О',
        passport: 'Паспорт',
        dateOfBirth: 'Дата рождения',
        phone: 'Телефон',
        vehicleType: 'Тип транспортного средства',
      },
      ApplicationsForGai: {
        application: 'Заявки',
        clear: 'Очистить',
        search: 'Найти',
        fio: 'Ф.И.О',
        phone: 'Телефон',
        appCode: 'Код заявки',
        passCode: 'Номер пропуска',
        status: 'Статус',
        category: 'Категория',
        transportType: 'Тип транспортного средства',
        updatedAt: 'Дата изменения',
        actions: 'Действия',
        serial: 'Серия',
        number: 'Номер',
        pinfl: 'ПИНФЛ',
        createdAt: 'Дата создания',
        endDate: 'Дата окончания',
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        categories: 'Категория',
        vehicleTypes: 'Тип транспортного средства',
        statuses: 'Статус',
      },
      VisitLogsPage: {
        visitLogs: 'История посещений',
        date: 'Дата',
        search: 'Поиск',
        clear: 'Очистить',
        fio: 'Ф.И.О',
        dateOfBirth: 'Дата рождения',
        passport: 'Серия/номер паспорта',
        appCategory: 'Категория заявки',
        createdAt: 'Дата создания',
        status: 'Статус',
      },
      VehicleTypesPage: {
        deleted: 'Успешно удалено',
        wantDeleteVehicle:
          'Вы действительно хотите удалить транспортное средство?',
        vehicleTypes: 'Транспортные средства',
        name: 'Название',
        actions: 'Действия',
      },
      UsersPage: {
        error: 'Ошибка, попробуйте позже',
        users: 'Пользователи',
        serial: 'Серия',
        number: 'Номер',
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        search: 'Поиск',
        clear: 'Очистить',
        passport: 'Серия/номер паспорта',
        pinfl: 'ПИНФЛ',
        fio: 'Ф.И.О',
        phone: 'Телефон',
        integrated: 'Интегрирован',
        role: 'Роль',
        verrified: 'Подтвержден',
        status: 'Статус',
        actions: 'Действия',
      },
      UserIdentificationPage: {
        error: 'Ошибка, попробуйте позже',
        applicationNotFound: 'Заявка не найдена',
        userNotActive: 'Пользователь не активен',
        statusError: 'Ошибка статуса',
        appNotVerrified: 'Заявка не подтверждена',
        fio: 'Ф.И.О',
        dateOfBirth: 'Дата рождения',
        phone: 'Телефон',
        passport: 'Cерия и номер паспорта',
        pinfl: 'ПИНФЛ',
        appStatus: 'Статус заявки',
        appCategory: 'Категория заявки',
        failureDays:
          'Для повторной сдачи экзамена, должно пройти не менее 7 рабочих дней со дня проведенного экзамена',
        success: 'Успешно',
      },
      UpdateVehicleTypePage: {
        fillFields: 'Заполните поле',
        vehicletypeUpdated: 'Транспортное средство изменено',
        updateVehicleType: 'Изменить транспортное средство',
        enterName: 'Введите название',
        back: 'Назад',
        save: 'Сохранить',
      },
      UpdateUserPage: {
        motordrome: 'Автодром',
        phoneError: 'Пользователь с таким телефоном уже существует',
        passportError: 'Пользователь с таким паспортом уже существует',
        pinflError: 'Пользователь с таким ПИНФЛ уже существует',
        updateUser: 'Обновить пользователя',
        serial: 'Серия',
        number: 'Номер',
        phone: 'Номер телефона',
        role: 'Роль',
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        dateOfBirth: 'Дата рождения',
        pinfl: 'ПИНФЛ',
        getToken: 'Получить токен',
        back: 'Назад',
        save: 'Сохранить',
      },
      UpdateStatusPage: {
        updateStatus: 'Обновить статус',
        type: 'Выберите тип',
        queue: 'Выберите очередь',
        pass: 'Пропуск',
        pay: 'Оплата',
        name: 'Название',
        titleLat: 'Заголовок на узбекском (латиница)',
        titleCyr: 'Заголовок на узбекском (кириллица)',
        titleRu: 'Заголовок на русском',
        order: 'Порядок',
        back: 'Назад',
        update: 'Обновить',
        vehicleType: 'Тип транспортного средства',
      },
      UpdatePracticePenaltyPage: {
        updatePenalty: 'Обновить штрафные баллы',
        nameLat: 'Название на узбекском (латиница)',
        nameCyr: 'Название на узбекском (кириллица)',
        nameRu: 'Название на русском',
        points: 'Баллы',
        cancel: 'Отмена',
        save: 'Сохранить',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      UpdatePracticeExercisesPage: {
        updateExercise: 'Обновить упражнение',
        nameLat: 'Название на узбекском (латиница)',
        nameCyr: 'Название на узбекском (кириллица)',
        nameRu: 'Название на русском',
        cancel: 'Отмена',
        save: 'Сохранить',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      UpdateErrorTypePage: {
        fillFields: 'Заполните поля',
        errorTypeChanged: 'Тип ошибки изменен',
        error: 'Произошла ошибка, попробуйте позже',
        updateErrorType: 'Изменить тип ошибки',
        nameCyr: 'Название на узбекском (кириллица)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      UpdateDocPage: {
        required: 'Обязательный/Не обязательный',
        nameConflict: 'Документ с таким названием уже существует',
        fillFields: 'Заполните поля',
        docUpdated: 'Документ изменен',
        error: 'Произошла ошибка, попробуйте позже',
        updateDocument: 'Редактировать документ',
        docName: 'Название документа',
        nameCyr: 'Название на узбекском (кириллица)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      UpdateCategoryPage: {
        fillFields: 'Заполните поля',
        categoryUpdated: 'Категория изменена',
        error: 'Произошла ошибка, попробуйте позже',
        updateCategory: 'Редактировать категорию',
        data: 'Данные',
        statuses: 'Статусы',
        categoryName: 'Название категории',
        nameCyr: 'Название на узбекском (кириллица)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        order: 'Порядок',
        selectDocuments: 'Выберите документы',
        back: 'Назад',
        save: 'Сохранить',
        vehicleTypes: 'Типы транспортных средств',
      },
      ApplicationHistoryPage: {
        branch: 'Филиал',
        actions: 'Действие',
        more: 'Подробнее',
        applicationHistory: 'История заявки',
        history: 'История',
        result: 'Результат',
        success: 'Правильно',
        fail: 'Неверно',
        answer: 'Ответ',
        question: 'Вопрос',
        ticketNumber: 'Номер билета',
        createdDate: 'Дата создания',
        statuses: 'Статусы',
        transactions: 'Транзакции',
        results: 'Результаты',
        oldStatus: 'Предыдущий статус',
        nextStatus: 'Следующий статус',
        currentStatus: 'Текущий статус',
        category: 'Категория',
      },
      UpdateApplicationPage: {
        resident: 'Гражданин РУз',
        noResident: 'Иностранец',
        isForeigner: 'Гражданин РУз / Иностранец',
        time: 'Время',
        branch: 'Филиал',
        choiceBranch: 'Выберите филиал',
        residencePlace: 'Место проживания',
        more: 'Подробнее о заявке',
        uploadDocuments: 'Заполните документы',
        notInList: 'Нет в списке',
        enterSchoolName: 'Введите название автошколы',
        choiceSchool: 'Выберите автошколу',
        selectLanguage: 'Выберите язык прохождения экзамена',
        visitDateNotValid: 'Не верная дата визита в центр',
        visitDate: 'Дата визита в центр',
        verification: 'Верифицированный пользователь',
        confirmedApplication: 'Заявка подтверждена',
        printCertificate: 'Распечатать сертификат',
        correctPhone: 'Введите корректный номер телефона',
        userNotReceived: 'Не получены данные пользователя',
        fillFields: 'Заполните поля',
        vehicleType: 'Укажите тип транспортного средства',
        applicationsUpdated: 'Заявка изменена',
        error: 'Произошла ошибка, попробуйте позже',
        canceledApplication: 'Отмените заявку',
        passportConflict: 'Пользователь с таким паспортом уже существует',
        applicationRejected: 'Заявка отклонена',
        application: 'Заявка',
        updateApplication: 'Изменить заявку',
        cancelApp: 'Отменить и сохранить заявку',
        save: 'Сохранить',
        appData: 'Данные заявки',
        transport: 'Транспорт',
        userData: 'Данные пользователя',
        userPhone: 'Введите номер телефона пользователя',
        passportSeries: 'Серия паспорта',
        passportNumber: 'Номер паспорта',
        pinfl: 'ПИНФЛ',
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        dateOfBirth: 'Дата рождения',
        back: 'Назад',
        phone: 'Телефон',
        category: 'Категория',
        transportType: 'Тип транспорта',
        reasonsRejectedApp: 'Причины отклонения заявки',
        docsList: 'Список документов',
        passIssue: 'Оформить пропуск',
        selectCategoryAndTryAgain: 'Выберите категорию и попробуйте снова',
        passIssued: 'Пропуск оформлен',
        confirmApplication: 'Подтвердить и сохранить заявку',
        errorTryLater: 'Ошибка попробуйте позже',
        conflictPinfl: 'Пользователь с таким ПИНФЛ уже существует',
        conflictSerialNumber:
          'Пользователь с такой серией и номером уже существует',
        conflictPhone: 'Пользователь с таким номером телефона уже существует',
      },
      TransactionsPage: {
        transactions: 'Транзакции',
        fio: 'Ф.И.О',
        paymentDate: 'Дата оплаты',
        paymentType: 'Тип оплаты',
        sum: 'Сумма',
        passport: 'Паспортные данные',
        pinfl: 'ПИНФЛ',
        id: 'ID',
        cardNumber: 'Карта(4 посл цифры)',
        datails: 'Детали',
        cashierName: 'Ф.И.О кассира',
        date: 'Дата',
        transactionsStatus: 'Статус транзакции',
        transactionsType: 'Тип транзакции',
        search: 'Поиск',
        clear: 'Очистить',
        CASH: 'Наличные',
        TERMINAL: 'Терминал',
        PAYME: 'Payme',
        CLICK: 'Сlick',
        REFUND: 'Возврат',
        FREE: 'Бесплатно',
        UZCARD: 'UZCARD',
        HUMO: 'HUMO',
        BANK: 'BANK',
        EPOS: 'EPOS',
        THEORY_PAYMENT: 'Теоретический экзамен',
        PRACTICE_PAYMENT: 'Практический экзамен',
        examType: 'Тип экзамена',
      },
      StatusesPage: {
        categoryDeleted: 'Категория удалена',
        error: 'Произошла ошибка, попробуйте позже',
        statuses: 'Статусы',
        order: 'Порядок',
        name: 'Название',
        titleLat: 'Название на узбекском (латиница)',
        titleCyr: 'Название на узбекском (кириллица)',
        titleRu: 'Название на русском',
        pass: 'Пропуск',
        actions: 'Действия',
        wantDeleteStatus: 'Вы действительно хотите удалить cтатус?',
      },
      PracticePenaltiesPage: {
        penaltiesDeleted: 'Штрафные баллы удалены',
        error: 'Произошла ошибка, попробуйте позже',
        penalties: 'Штрафы',
        nameLat: 'Название на узбекском (латиница)',
        nameCyr: 'Название на узбекском (кириллица)',
        nameRu: 'Название на русском',
        penaltyPoints: 'Штрафные очки',
        actions: 'Действия',
        code: 'Код',
        wantDeletePenalty: 'Вы действительно хотите удалить штрафные баллы?',
      },
      PracticeExercisesPage: {
        exserciseDeleted: 'Упражнение удалено',
        error: 'Произошла ошибка, попробуйте позже',
        praticeExercises: 'Практические упражнения',
        titleLat: 'Заголовок на узбекском (латиница)',
        titleCyr: 'Заголовок на узбекском (кириллица)',
        titleRu: 'Заголовок на русском',
        wantDeleteExercise: 'Вы действительно хотите удалить упражнение?',
        code: 'Код',
      },
      PaymentPage: {
        payment: 'Оплата',
        search: 'Найти',
        fio: 'Ф.И.О',
        categories: 'Категория',
        statuses: 'Статус',
        certificate: 'Сертификат',
        printCertificate: 'Распечетать сертификат',
        sum: 'Сумма',
        allSum: 'Вся сумма',
        cash: 'НАЛИЧНЫЕ',
        bankCard: 'ТЕРМИНАЛ',
        pay: 'Оплатить',
        payDate: 'Дата оплаты',
        paymentType: 'Тип оплаты',
        error: 'Ошибка',
      },
      ErrorTypesPage: {
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
        errorTypeDeleted: 'Тип ошибки удален',
        error: 'Произошла ошибка, попробуйте позже',
        errors: 'Ошибки',
      },
      MotordromesPage: {
        motordromeDeleted: 'Автодром удален',
        error: 'Произошла ошибка, попробуйте позже',
        wantDeleteMotordrome: 'Вы действительно хотите удалить автодром?',
        motordromes: 'Автодромы',
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
      SchoolPage: {
        tin: 'ИНН',
        schoolDeleted: 'Школа удалена',
        error: 'Произошла ошибка, попробуйте позже',
        wantDeleteSchool: 'Вы действительно хотите удалить школу?',
        search: 'Поиск',
        clear: 'Очистить',
        schools: 'Школы',
        enterName: 'Введите название для поиска',
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
      DocsPage: {
        docDeleted: 'Документ удален',
        error: 'Произошла ошибка, попробуйте позже',
        wantDeleteDoc: 'Вы дейтвительно хотите удалить документ?',
        docs: 'Документы',
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
      CreateVehicleTypePage: {
        fillFields: 'Заполните поле',
        createdVahicleType: 'Транспортная категория создана',
        createCategory: 'Создать категорию по вождению автомобиля',
        name: 'Введите название',
        back: 'Назад',
        save: 'Сохранить',
      },
      CreateStatusPage: {
        createStatus: 'Создать статус',
        type: 'Выберите тип',
        queue: 'Выберите очередь',
        pass: 'Пропуск',
        payment: 'Оплата',
        name: 'Название',
        titleLat: 'Название на узбекском (латиница)',
        titleCyr: 'Название на узбекском (кириллица)',
        titleRu: 'Название на русском',
        order: 'Порядок',
        back: 'Назад',
        save: 'Сохранить',
        vehicleType: 'Тип транспортного средства',
      },
      CreatePracticePenaltyPage: {
        createPenalty: 'Создать штрафные баллы',
        nameLat: 'Название на узбекском (латиница)',
        nameCyr: 'Название на узбекском (кириллица)',
        nameRu: 'Название на русском',
        scores: 'Баллы',
        cancel: 'Отмена',
        save: 'Сохранить',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      CreatePracticeExercisesPage: {
        createExercisse: 'Создать упражнение',
        nameLat: 'Название на узбекском (латиница)',
        nameCyr: 'Название на узбекском (кириллица)',
        nameRu: 'Название на русском',
        cancel: 'Отмена',
        save: 'Сохранить',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      CreateErrorTypePage: {
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        errorCreateSuccesed: 'Тип ошибки создан успешно',
        createError: 'Создать тип ошибки',
        nameCyr: 'Название на узбекском (кириллица)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      UpdateMotordromePage: {
        nameCyr: 'Название на узбекском (кириллица)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        conflictError: 'Автодром в данной очереди уже существует',
        selectQueue: 'Выберите очередь',
        updateMotordrome: 'Изменить автодром',
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        updatedMotordrome: 'Автодром изменен',
        name: 'Название автодрома',
        back: 'Назад',
        save: 'Сохранить',
        vehicleTypes: 'Transport vositalari',
      },

      CreateMotordromePage: {
        conflictError: 'Автодром в данной очереди уже существует',
        selectQueue: 'Выберите очередь',
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        motordromeCreatedSuccess: 'Автодром создан успешно',
        createMotordrome: 'Создать автодром',
        nameCyr: 'Название на узбекском (кириллица)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        name: 'Название автодрома',
        back: 'Назад',
        save: 'Сохранить',
        vehicleTypes: 'Типы транспортного средства',
      },
      UpdateSchoolPage: {
        tin: 'ИНН',
        conflict: 'Школа уже существует',
        tinConflict: 'Школа с таким ИНН уже существует',
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        name: 'Название школы',
        save: 'Сохранить',
        back: 'Назад',
        updateSchool: 'Изменить школу',
        updatedSchool: 'Школа изменена',
      },
      CreateSchoolPage: {
        tin: 'ИНН',
        conflict: 'Школа уже существует',
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        schoolCreatedSuccess: 'Школа создана успешно',
        createSchool: 'Создать школу',
        name: 'Название',
        save: 'Сохранить',
        back: 'Назад',
      },
      CreateDocPage: {
        required: 'Обязательный/Не обязательный',
        nameConflict: 'Документ с таким названием уже существует',
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        docCreateSuccesed: 'Документ создан успешно',
        createDoc: 'Создать документ',
        nameCyr: 'Название на узбекском (кириллица)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        name: 'Название документа',
        back: 'Назад',
        save: 'Сохранить',
      },
      CreateCategoryPage: {
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        categoryCreatSuccessed: 'Категория создана успешно',
        createdCategory: 'Создать категорию',
        categoryName: 'Название категории',
        nameCyr: 'Название на узбекском (кириллица)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        order: 'Порядок',
        selectDocuments: 'Выберите документы',
        back: 'Назад',
        save: 'Сохранить',
        vehicleTypes: 'Типы транспортных средств',
      },
      CreateApplicationPageByUser: {
        resident: 'Гражданин РУз',
        noResident: 'Иностранец',
        isForeigner: 'Гражданин РУз / Иностранец',
        choiceBranch: 'Выберите филиал',
        residencePlace: 'Место проживания',
        enterSchoolName: 'Введите название школы',
        notInList: 'Нет в списке',
        choiceSchool: 'Выберите автошколу',
        fillFields: 'Заполните поля',
        selectLanguage: 'Выберите язык прохождения экзамена',
        visitDateNotValid: 'Не верная дата визита в центр',
        visitDate: 'Дата визита в центр',
        fillDocs: 'Заполните документы',
        selectCategory: 'Выберите категорию',
        applicationSuccessCreated: 'Заявка создана успешно',
        error: 'Произошла ошибка, попробуйте позже',
        vehicleType: 'Укажите тип транспортного средства',
        createApplication: 'Создать заявку',
        selectVehicleType: 'Выберите тип транспорта',
        documentList: 'Список документов',
        back: 'Назад',
        save: 'Сохранить',
        applicationAlreadyExists: 'Заявка с такой категорией уже существует',
        time: 'Время',
      },
      CreateApplicationPage: {
        resident: 'Гражданин РУз',
        noResident: 'Иностранец',
        isForeigner: 'Гражданин РУз / Иностранец',
        residencePlace: 'Место проживания',
        notInList: 'Нет в списке',
        enterSchoolName: 'Введите название автошколы',
        choiceSchool: 'Выберите автошколу',
        clear: 'Очистить',
        selectLanguage: 'Выберите язык прохождения экзамена',
        fillFields: 'Заполните поля',
        vehicleType: 'Укажите тип транспортного средства',
        uploadDocuments: 'Загрузите документы',
        applicationSuccessCreated: 'Заявка создана успешно',
        applicationAlreadyExists: 'Заявка с такой категорией уже существует',
        error: 'Произошла ошибка, попробуйте позже',
        userNotFound: 'Пользователь не найден',
        createApplication: 'Создать заявку',
        enterPhoneNumber: 'Введите номер телефона пользователя',
        pinfl: 'ПИНФЛ',
        passportSerial: 'Серия паспорта',
        passportNumber: 'Номер паспорта',
        selectCategory: 'Выберите категорию',
        selectVehicleType: 'Выберите тип транспорта',
        userData: 'Данные пользователя',
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        dateOfBirth: 'Дата рождения',
        documentList: 'Список документов',
        back: 'Назад',
        save: 'Сохранить',
        passIssued: 'Пропуск оформлен',
        choiceCategory: 'Выберите категорию',
        enterPassport: 'Введите серию и номер паспорта',
        errorPinfl: 'Ошибка в поле ПИНФЛ',
        errorPhone: 'Ошибка в поле номер телефона',
      },
      CategoriesPage: {
        deletedCategory: 'Категория удалена',
        error: 'Произошла ошибка',
        wantDeleteCategory: 'Вы действительно хотите удалить категорию?',
        categories: 'Категории',
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
      CashPayment: {
        cashPayment: 'Оплата наличными',
        fio: 'Ф.И.О',
        passport: 'Серия/номер паспорта',
        pinfl: 'ПИНФЛ',
        date: 'Дата оплаты',
        sum: 'Сумма оплаты',
        details: 'Детали',
        amountEnclosed: 'Инкассированная сумма',
        dateEnclosed: 'Дата инкасации',
        fioCashier: 'ФИО кассира, принимающего оплату',
        idMachine: 'Идентификационный номер платежного аппарата',
      },
      BankCardPayment: {
        cardPayment: 'Оплата банк картой',
        fio: 'Ф.И.О',
        passport: 'Серия/номер паспорта',
        pinfl: 'ПИНФЛ',
        date: 'Дата оплаты',
        sum: 'Сумма оплаты',
        details: 'Детали',
        lastNumber: 'Последние 4 цифры карты',
        fioCashier: 'ФИО кассира, принимающего оплату',
        idMachine: 'Идентификационный номер платежного аппарата',
      },
      ApplicationsPage: {
        operator: 'Оператор',
        branch: 'Филиал',
        application: 'Заявки',
        clear: 'Очистить',
        search: 'Найти',
        fio: 'Ф.И.О',
        phone: 'Телефон',
        appCode: 'Код заявки',
        passCode: 'Номер пропуска',
        status: 'Статус',
        category: 'Категория',
        transportType: 'Тип транспорта',
        updatedAt: 'Дата изменения',
        actions: 'Действия',
        serial: 'Серия',
        number: 'Номер',
        pinfl: 'ПИНФЛ',
        createdAt: 'C',
        endDate: 'По',
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        categories: 'Категория',
        vehicleTypes: 'Тип транспортного средства',
        statuses: 'Статус',
        creationType: 'Тип заявки',
      },
      auth: {
        notFoundUser: 'Номер телефона не найден',
        enterOtpCode: 'Введите код смс',
        passwordNotConfirmed: 'Пароли не совпадают',
        passwordNotValid: 'Длина пороля должена быть не менее 6 символов',
        login: 'Войти',
        phoneNumber: 'Номер телефона',
        password: 'Пароль',
        forgotPassword: 'Забыли пароль',
        confirmPassowrd: 'Подтвердитe пароль',
        register: 'Регистрация',
        resetPassword: 'Сбросить пароль',
        send: 'Отправить',
        confirmOffer: 'Подтвердите публичную оферту',
        enterCode: 'Введите смс код',
        newPassword: 'Новый пароль',
        smsCodeReplay: 'Отправить смс заново',
        sendCodeToMobile: 'На ваш номер отправлено смс с кодом',
        sendSmsLater: 'Повторить отправку смс через',
        sendSmsAgain: 'Повторить отправку СМС',
        authSuccess: 'Авторизация прошла успешно',
        error: 'Проверьте правильность заполнения данных',
        notValidLoginOrPassword: 'Неверный логин или пароль',
        tryLaterAgain: 'Ошибка, попробуйте позже',
        editedPassword: 'Пароль изменен!',
        registed: 'Зарегистрироваться',
        codeNotValid: 'Неверный смс код',
        registrationSuccess: 'Регистрация прошла успешно',
        alreadyUser: 'Пользователь уже существует',
        repeatPassword: 'Повторите пароль',
        offer: 'Публичная оферта',
        back: 'Назад',
        cancel: 'Отмена',
        sendCode: 'Отправить код',
        checkCorrectData: 'Проверьте правильность заполнения данных',
        passwordChanged: 'Пароль изменен',
      },
      updateQueuePage: {
        choiceBranch: 'Выберите филиал',
        choiceQueueType: 'Выберите тип очереди',
        queueUpdated: 'Очередь изменена',
        practice: 'Практика',
        theory: 'Теория',
        fillFields: 'Заполните поля',
        tryAgainLater: 'Произошла ошибка, попробуйте позже',
        updateQueue: 'Изменить очередь',
        nameOz: 'Название на узбекском (кирилица)',
        nameUz: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      createQueuePage: {
        choiceBranch: 'Выберите филиал',
        choiceQueueType: 'Выберите тип очереди',
        queueCreated: 'Очередь создана успешно',
        practice: 'Практика',
        theory: 'Теория',
        fillFields: 'Заполните поля',
        tryAgainLater: 'Произошла ошибка, попробуйте позже',
        createQueue: 'Создать очередь',
        nameOz: 'Название на узбекском (кирилица)',
        nameUz: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      carsPage: {
        filter: 'Фильтр',
        choiceMotordrome: 'Выберите автодром',
        search: 'Найти',
        number: 'Номер',
        model: 'Модель',
        category: 'Категория',
        cars: 'Автомобили',
      },
      queuePage: {
        branches: 'Филиал',
        confirmDelete: 'Удалить из очереди?',
        confirmLogout: 'Вы действительно хотите выйти?',
        usersCount: 'Количество людей в очереди',
        classRoom: 'Кабинет',
        selectClassRoom: 'Выберите кабинет',
        emptyQueue: 'Очередь пуста',
        confirmDeleteQueue: 'Вы действительно хотите удалить эту очередь?',
        selectQueue: 'Выберите очередь',
        clearedQueue: 'Очередь очищена',
        clear: 'Очистить',
        enterCount: 'Введите количество',
        power: 'Запустить',
        name: 'Название',
        updateDate: 'Дата изменения',
        actions: 'Действия',
        notAccept: 'Отказано в доступе, обратитесь в ресепшн',
        passNotValid: 'Пропуск не действителен',
        deletedQueue: 'Очередь удалена',
        queue: 'Очередь',
      },
    },
  },
  oz: {
    general: {
      instance: {
        noAccess: 'Кириш тақиқланган',
        login: 'Тизимга киринг',
        uploadDocuments: 'Хужжатларни юкланг',
      },
    },
    components: {
      Header: {
        prompt: 'Сиз ростдан чиқиб кетмоқчимисиз?',
      },
      transactionModal: {
        replenished: 'Пополнение',
        writeOff: 'Списание',
        actionType: 'Действие',
        pay: 'Оплата',
        payme: 'Payme',
        click: 'Click',
        terminal: 'Терминал',
        cash: 'Наличные',
        title: 'Транзакции',
        transactionType: 'Тип',
        amount: 'Сумма',
        status: 'Статус',
        payDate: 'Дата оплаты',
        completed: 'Успешно',
        failed: 'Ошибка',
      },
      resultsModal: {
        seeApp: 'Посмотреть заявку',
      },
      newApplicationNotice: {
        newNotice: 'Янги онлайн ариза',
        open: 'Кўриш',
        close: 'Закрыть',
      },
      passModal: {
        enterPassNumber: 'Бейжик рақамини киритинг',
        save: 'Сақлаш',
      },
      pagination: {
        rowsPerPage: 'Саҳифадаги сони',
      },
      queueList: {
        car: 'Автомобиль',
        classRoom: 'Аудитория',
        choiceCar: 'Автомобилни танланг',
        call: 'Чақириш',
        cancel: 'Бекор қилиш',
        delete: 'Ўчириш',
        category: 'Тоифа',
      },
      //fix
      TimeLine: {
        tests: 'Тест-синовлар',
        right: 'Тўғри',
        wrong: 'Нотўғри',
        total: 'Жами йиғилган баллар',
        startDate: 'Бошлаш санаси',
        attempts: 'Уринишлар',
        carNumber: 'Машина рақами',
        penaltyPoints: 'Жарима баллари',
      },
      Sidebar: {
        reports: 'Протоколлар',
        schools: 'Мактаблар',
        enterName: 'Қидириш учун ном киритинг',
        control: 'Навбатни бошқариш',
        classRooms: 'Кабинет',
        cars: 'Автомобиллар',
        queue: 'Навбат',
        users: 'Фойдаланувчилар',
        categories: 'Тоифалар',
        docs: 'Хужжатлар',
        applications: 'Аризалар',
        statuses: 'Статуслар',
        practiceCategory: 'Транспорт воситалари турлари',
        payment: 'Тўлов',
        passedExam: 'Имтиҳондан ўтганлар',
        cashPayment: 'Нақд тўлов',
        userId: 'Фойдаланувчини аниқлаш',
        visitLogs: 'Ташрифлар рўйхати',
        motordromes: 'Автодром',
        errors: 'Хатолар',
        transactions: 'Транзакциялар',
        verrifyApps: 'Ҳақиқийлиги тасдиқланган аризалар',
        generatReport: 'Умумий ҳисобот',
        generalStatistics: 'Статистика отчет',
        schoolStatistics: 'Мактаблар статистикаси',
        gaiSchoolsReport: 'ЙҲХББнинг мактаблар бўйича ҳисоботи',
        sumarry: 'Хулоса',
        appeals: 'Апелляция',
        report: 'Рапортлар',
        vising: 'Визинг',
        requestForChangeData: 'Маълумотларни ўзгартириш бўйича сўровлар',
        certificates: 'Сертификатлар',
        certificatesHistory: 'История сертификата',
        calendar: 'Тақвим',
        fines: 'Жарималар',
        reception: 'Қабул',
        security: 'Xaвфсизлик',
        accounting: 'Бухгалтерия хисоби',
        cash: 'Касса',
        statistics: 'Статистика',
        integrationWithGai: 'Интеграция с гаи',
      },
      CategoryStatuses: {
        statusDeleted: 'Статус ўчирилган',
        erorrTryAgain: 'Хатолик юз берди. Яна бир бор уриниб кўринг',
        wantToRemove: 'Сиз ҳақиқатан ҳам тоифани статусини ўчирмоқчимисиз?',
        add: 'Тоифани статусини қўшиш',
        status: 'Статус',
        successStatus: 'Муваффақиятли натижани статуси',
        failureStatus: 'Муваффақиятсиз натижани статуси',
        order: 'Тартиб',
        start: 'Бошланди',
        finished: 'Тугади',
        createdAt: 'Яратилган санаси',
        actions: 'Ҳаракатлар',
      },
      Confirmation: {
        cancel: 'Бекор қилиш',
        success: 'Тасдиқлаш',
      },
      FileUploader: {
        fileSize: 'Файл ҳажми жуда катта',
        selectDocument: 'Хужжатни танлаш',
        selectAnotherDocument: 'Бошқа хужжатни танлаш',
        scanning: 'Сканерлаш',
      },
      FileUploads: {
        mainPassport: 'Паспорт',
        photo: 'Фотосурат',
        passport: 'Паспорт/ID карта (прописка билан)',
        employerReferral: 'Иш берувчининг йўлланмаси',
        replacement:
          'Ягона намунадаги гувоҳнома ёки узлуксиз ҳайдовчилик меҳнат фаолияти 10 йилдан ортиқ бўлган «B» ва «C» тоифали ҳайдовчилик гувоҳномаси бўлган шахсларнинг ҳайдовчилик гувоҳномасини «BЕ» ва «CЕ» тоифага алмаштириш ҳайдовчининг меҳнат дафтарчасидан кўчирма асосида',
        driverLicence_1: 'Миллий ҳайдовчилик гувоҳномаси-1',
        driverLicence_2: 'Миллий ҳайдовчилик гувоҳномаси-2',
        direction: 'Ички ишлар ваколатли органининг йўлланмаси',
        honey083_1: '083-шаклдаги тиббий маълумотнома-1',
        honey083_2: '083-шаклдаги тиббий маълумотнома-2',
        diplomaAnnex:
          'Режа асосида фанларни ўзлаштирилганлиги тўғрисида диплом ва унга илова',
        nationalDriversLicence:
          'Миллий ҳайдовчилик гувоҳномаси («C», «B» ва «C», «D», «C» и «D», «СE» ва (ёки) «DE»)',
        certificateSingleSample: 'Ягона намунадаги Гувоҳнома',
      },
      Footer: {
        address: 'Тошкент шаҳри, Янгиҳаёт тумани, Файзли Маҳалласи, 87',
        copyRight: '2022 барча ҳуқуқлар ҳимояланган',
      },
      CreateCategoryStatuses: {
        fillFields: 'Катакларни тўлдиринг',
        createdSuccess: 'Тоифа статуси муваффақиятли яратилган',
        alreadyExistsDefault:
          '«Дастлабки» статуси билан тоифа статуси аллақачон мавжуд»',
        alreadyExistsFinish:
          '«Тугатилган» статуси билан тоифа статуси аллақачон мавжуд',
        errorTryAgain: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        createStatusCategory: 'Тоифа учун статус яратиш',
        selectStatus: 'Статусни танланг',
        successStatus: 'Муваффақиятли натижани статуси',
        failureStatus: 'Муваффақиятсиз натижани статуси',
        workDays: 'Қайта уринишдан олдин иш кунлари сони',
        order: 'Тартиб',
        defaultStatus: '«Дастлабки» статус',
        finished: 'Тугади',
        cancel: 'Бекор қилиш',
        save: 'Сақлаш',
      },
      UpdateCategoryStatuses: {
        fillFields: 'Катакларни тўлдиринг',
        updateSuccess: 'Тоифа статуси ўзгартирилган',
        errorTryAgain: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        updateStatusCategory: 'Тоифа учун статус ўзгартириш',
        selectStatus: 'Статусни танланг',
        successStatus: 'Муваффақиятли натижани статуси',
        failureStatus: 'Муваффақиятсиз натижани статуси',
        cost: 'Нарҳ',
        workDays: 'Қайта уринишдан олдин иш кунлари сони',
        order: 'Тартиб',
        defaultStatus: '«Дастлабки» статус',
        finished: 'Тугади',
        cancel: 'Бекор қилиш',
        save: 'Сақлаш',
        alreadyExistsDefault:
          '«Дастлабки» статуси билан тоифа статуси аллақачон мавжуд',
        alreadyExistsFinish:
          '«Тугатилган» статуси билан тоифа статуси аллақачон мавжуд',
      },
      TokenModal: {
        copy: 'Нусҳа олинди',
        refresh: 'Янгилаш',
      },
      UserModal: {
        userInfo: 'Фойдаланувчи ҳақида маълумот',
        FIO: 'Ф.И.Ш.',
        dateOfBirth: 'Туғилган санаси',
        passport: 'Паспорт серияси/рақами',
        pinfl: 'ЖШШИР',
        phone: 'Телефон рақами',
        cancel: 'Бекор қилиш',
        successUser: 'Фойдаланувчини тасдиқлаш',
        name: 'Номи',
        createdAt: 'Яратилган санаси',
        actions: 'Ҳаракатлар',
      },
    },
    pages: {
      //fix
      ReportPage: {
        //fix
        reportHistory: 'История протокола',
        FIO: 'ФИО',
        category: 'Категория',
        school: 'Школа',
        theryAttempt: 'Попытки теория',
        thoryScore: 'Балы теория',
        practiceAttempt: 'Поптыки практика',
        practiceScore: 'Сдал/Не сдал',
        passed: 'Сдал',
        notPassed: 'Не сдал',
        certificateNumber: 'Номер сертификата',
        print: 'Распечатать',
      },
      ReportsPage: {
        enterDate: 'Введите дату',
        count: 'Количество',
        actions: 'Действие',
        createdAt: 'Дата создания',
        dayNumber: 'Число генерации протокола',
        reports: 'Протоколы',
        generation: 'Сгенерировать',
        name: 'Название',
        date: 'Дата',
      },
      SummaryPage: {
        branch: 'Филиал',
        from: 'С',
        to: 'По',
        branches: 'Филиалы',
        summary: 'Сводка',
        application: 'Заявка',
        clear: 'Очистить',
        search: 'Найти',
        fio: 'Ф.И.О',
        appCode: 'Код заявки',
        status: 'Статус',
        createdAt: 'Дата создания',
        firstName: 'Имя',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        categories: 'Категория',
        vehicleTypes: 'Тип транспортного средства',
        oldStatus: 'Предыдущий статус',
        newStatus: 'Новый статус',
        attempt: 'Попытка',
        attempts: 'Попытки',
        firstAttempt: 'Первая попытка',
        retake: 'Пересдача',
        passport: 'Серия/Номер паспорта',
        phone: 'Телефон',
        result: 'Результат',
        true: 'Успешно',
        false: 'Не успешно',
      },
      UpdateClassRoomPage: {
        choiceBranch: 'Выберите филиал',
        nameError: 'Название кабинета уже существует',
        fillFields: 'Заполните поля',
        updatedClassRoom: 'Кабинет изменен',
        updateClassRoom: 'Изменить кабинет',
        enterName: 'Введите название',
        back: 'Назад',
        save: 'Сохранить',
      },
      //fix
      CreateClassRoomPage: {
        choiceBranch: 'Выберите филиал',
        nameError: 'Название кабинета уже существует',
        fillFields: 'Заполните поля',
        createdClassRoom: 'Кабинет создан',
        createClassRoom: 'Создать кабинет',
        name: 'Название',
        back: 'Назад',
        save: 'Сохранить',
      },
      //fix
      ClassRoomsPage: {
        classRooms: 'Кабинеты',
        deleted: 'Кабинет удален',
        wantDeleteClassRoom: 'Вы действительно хотите удалить этот кабинет?',
        updatedDate: 'Дата изменения',
        actions: 'Действия',
        name: 'Название',
        classRoom: 'Кабинет',
      },
      ApplicationForGai: {
        fio: 'Ф.И.Ш.',
        passport: 'Паспорт',
        dateOfBirth: 'Туғилган санаси',
        phone: 'Телефон',
        vehicleType: 'Транспорт воситасининг тури',
      },
      ApplicationsForGai: {
        application: 'Аризалар',
        clear: 'Тозалаш',
        search: 'Қидирув',
        fio: 'Ф.И.Ш.',
        phone: 'Телефон рақами',
        appCode: 'Аризанинг коди',
        passCode: 'Бейжик рақами',
        status: 'Статус',
        category: 'Тоифа',
        transportType: 'Транспорт воситасининг тури',
        updatedAt: 'Ўзгартиришлар киритилган санаси',
        actions: 'Ҳаракатлар',
        serial: 'Серияси',
        number: 'Рақами',
        pinfl: 'ЖШШИР',
        createdAt: 'Яратилган санаси',
        endDate: 'Тугаш санаси',
        firstName: 'Исми',
        lastName: 'Фамилияси',
        middleName: 'Отасининг исми',
        categories: 'Тоифа',
        vehicleTypes: 'Транспорт воситалари',
        statuses: 'Статус',
      },
      VisitLogsPage: {
        visitLogs: 'Ташрифлар рўйхати',
        date: 'Сана',
        search: 'Қидирув',
        clear: 'Тозалаш',
        fio: 'Ф.И.Ш.',
        dateOfBirth: 'Туғилган санаси',
        passport: 'Паспорт серияси/рақами',
        appCategory: 'Аризанинг тури',
        createdAt: 'Яратилган санаси',
        status: 'Статус',
      },
      VehicleTypesPage: {
        deleted: 'Муваффақиятли ўчирилди',
        wantDeleteVehicle:
          'Сиз ҳақиқатан ҳам транспорт воситасини ўчиришни хоҳлайсизми?',
        vehicleTypes: 'Транспорт воситалари',
        name: 'Номи',
        actions: 'Ҳаракатлар',
      },
      //fix
      UsersPage: {
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        users: 'Фойдаланувчилар',
        serial: 'Серияси',
        number: 'Рақами',
        firstName: 'Исми',
        lastName: 'Фамилияси',
        middleName: 'Отасининг исми',
        search: 'Қидирув',
        clear: 'Тозалаш',
        passport: 'Паспорт серияси/рақами',
        pinfl: 'ЖШШИР',
        fio: 'Ф.И.Ш.',
        phone: 'Телефон рақами',
        integrated: 'Итеграция қилинган',
        role: 'Роли',
        verrified: 'Тасдиқланган',
        status: 'Статус',
        actions: 'Ҳаракатлар',
      },
      UserIdentificationPage: {
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        applicationNotFound: 'Ариза топилмади',
        userNotActive: 'Фойдаланувчи фаол эмас',
        statusError: 'Статус хатоси',
        appNotVerrified: 'Ариза тасдиқланмаган',
        fio: 'Ф.И.Ш.',
        dateOfBirth: 'Туғилган санаси',
        phone: 'Телефон рақами',
        passport: 'Паспорт серияси/рақами',
        pinfl: 'ЖШШИР',
        appStatus: 'Аризанинг статуси',
        appCategory: 'Аризанинг тури',
        failureDays:
          'Имтиҳонни қайта топшириш учун аввалги имтиҳон топширилган кунидан 7 иш куни ўтиши керак',
        success: 'Муваффақиятли',
      },
      UpdateVehicleTypePage: {
        fillFields: 'Катакларни тўлдиринг',
        vehicletypeUpdated: 'Транспорт воситаси ўзгартирилди',
        updateVehicleType: 'Транспорт воситасини ўзгартириш',
        enterName: 'Номини киритинг',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
      },
      UpdateUserPage: {
        motordrome: 'Автодром',
        phoneError: 'Бундай телефон билан фойдаланувчи мавжуд',
        passportError: 'Бундай паспорт билан фойдаланувчи мавжуд',
        pinflError: 'Бундай ЖШШИР билан фойдаланувчи мавжуд',
        updateUser: 'Фойдаланувчини янгилаш',
        serial: 'Серияси',
        number: 'Рақами',
        phone: 'Телефон рақами',
        role: 'Роли',
        firstName: 'Исми',
        lastName: 'Фамилияси',
        middleName: 'Отасининг исми',
        dateOfBirth: 'Туғилган санаси',
        pinfl: 'ЖШШИР',
        getToken: 'Токенни қабул қилиш',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
      },
      UpdateStatusPage: {
        updateStatus: 'Статусни янгилаш',
        type: 'Турини танланг',
        queue: 'Навбатни танланг',
        pass: 'Рухсатнома/бейжик',
        pay: 'Тўлов',
        name: 'Номи',
        titleLat: 'Ўзбек тилидаги сарлавҳа (лотин алифбоси)',
        titleCyr: 'Ўзбек тилидаги сарлавҳа (Кирилл алифбоси)',
        titleRu: 'Рус тилидаги сарлавҳа',
        order: 'Тартиб',
        back: 'Орқага қайтиш',
        update: 'Янгилаш',
        vehicleType: 'Тип транспортного средства',
      },
      UpdatePracticePenaltyPage: {
        updatePenalty: 'Жарима балларини янгилаш',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameRu: 'Рус тилидаги номи',
        points: 'Баллар',
        cancel: 'Бекор қилиш',
        save: 'Сақлаш',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      UpdatePracticeExercisesPage: {
        updateExercise: 'Машқни янгилаш',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameRu: 'Рус тилидаги номи',
        cancel: 'Бекор қилиш',
        save: 'Сақлаш',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      UpdateErrorTypePage: {
        fillFields: 'Катакларни тўлдиринг',
        errorTypeChanged: 'Хато тури ўзгартирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        updateErrorType: 'Хато турини ўзгартириш',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameRu: 'Рус тилидаги номи',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
      },
      UpdateDocPage: {
        required: 'Обязательный/Не обязательный',
        nameConflict: 'Документ с таким названием уже существует',
        fillFields: 'Катакларни тўлдиринг',
        docUpdated: 'Хужжат ўзгартирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        updateDocument: 'Хужжатни таҳрирлаш',
        docName: 'Хужжат номи',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameRu: 'Рус тилидаги номи',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
      },
      UpdateCategoryPage: {
        fillFields: 'Катакларни тўлдиринг',
        categoryUpdated: 'Тоифаси ўзгартирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        updateCategory: 'Тоифани таҳрирлаш',
        data: 'Маълумотлар',
        statuses: 'Статуслар',
        categoryName: 'Тоифа номи',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameRu: 'Рус тилидаги номи',
        order: 'Тартиб',
        selectDocuments: 'Хужжатни танланг',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
        vehicleTypes: 'Типы транспортных средств',
      },
      ApplicationHistoryPage: {
        branch: 'Филиал',
        actions: 'Ҳаракатлар',
        more: 'Батафсил',
        applicationHistory: 'Ариза тарихи',
        history: 'Тарих',
        result: 'Натижа',
        sucess: 'Тўғри',
        fail: 'Нотўғри',
        answer: 'Жавоб',
        question: 'Савол',
        ticketNumber: 'Билет рақами',
        createdDate: 'Яратилган сана',
        statuses: 'Статуслар',
        transactions: 'Транзакциялар',
        results: 'Натижалар',
        oldStatus: 'Олдинги ҳолат',
        nextStatus: 'Кейинги ҳолат',
        currentStatus: 'Жорий ҳолат',
        category: 'Тоифаси',
      },
      UpdateApplicationPage: {
        resident: 'Ўзбекистон Республикаси фуқароси',
        noResident: 'Хорижий давлат фуқароси',
        isForeigner:
          'Ўзбекистон Республикаси фуқароси / Хорижий давлат фуқароси',
        time: 'Соат',
        branch: 'Филиал',
        choiceBranch: 'Филиални танланг',
        residencePlace: 'Яшаш жойи',
        more: 'Подробнее о заявке',
        uploadDocuments: 'Заполните документы',
        notInList: 'Рўйхатда йўқ',
        enterSchoolName: 'Автомактабнинг номини киритинг',
        choiceSchool: 'Выберите автошколу',
        selectLanguage: 'Имтиҳонни ўтказиш тилини танланг',
        visitDateNotValid: 'Не верная дата визита в центр',
        visitDate: 'Марказга ташриф буюрган санаси',
        verification: 'Верифицированный пользователь',
        confirmedApplication: 'Ариза тасдиқланган',
        printCertificate: 'Сертификатни чоп этиш',
        correctPhone: 'Телефон рақамини тўғри киритинг',
        userNotReceived: 'Фойдаланувчининг маълумотлари қабул қилинмади',
        fillFields: 'Катакларни тўлдиринг',
        vehicleType: 'Транспорт воситасининг турини белгиланг',
        applicationsUpdated: 'Ариза ўзгартирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        canceledApplication: 'Аризани бекор қилинг',
        passportConflict: 'Бундай паспорт билан фойдаланувчи мавжуд',
        applicationRejected: 'Ариза рад этилди',
        application: 'Ариза',
        updateApplication: 'Аризани ўзгартириш',
        cancelApp: 'Аризани бекор қилиш',
        save: 'Сақлаш',
        appData: 'Аризанинг маълумотлари',
        transport: 'Транспорт воситаси',
        userData: 'Фойдаланувчининг маълумотлари',
        userPhone: 'Фойдаланувчининг телефон рақамини киритинг',
        passportSeries: 'Паспорт серияси',
        passportNumber: 'Паспорт рақами',
        pinfl: 'ЖШШИР',
        firstName: 'Исми',
        lastName: 'Фамилияси',
        middleName: 'Отасининг исми',
        dateOfBirth: 'Туғилган санаси',
        back: 'Орқага қайтиш',
        phone: 'Телефон рақами',
        category: 'Тоифаси',
        transportType: 'Транспорт воситасининг тури',
        reasonsRejectedApp: 'Аризани рад этиш сабаблари',
        docsList: 'Хужжатларнинг рўйхати',
        passIssue: 'Бейжикни расмийлаштириш',
        selectCategoryAndTryAgain: 'Тоифани танлаб қайта уриниб кўринг',
        passIssued: 'Бейжик расмийлаштирилди',
        confirmApplication: 'Аризани тасдиқлаш',
        errorTryLater: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        conflictPinfl: 'Пользователь с таким ПИНФЛ уже существует',
        conflictSerialNumber:
          'Бундай серия ва паспорт рақамига эга фойдаланувчи аллақачон мавжуд',
        conflictPhone:
          'Ушбу телефон рақамига эга фойдаланувчи аллақачон мавжуд',
      },
      TransactionsPage: {
        transactions: 'Транзакциялар',
        fio: 'Ф.И.Ш.',
        paymentDate: 'Тўлов санаси',
        paymentType: 'Тўлов турлари',
        sum: 'Суммаси',
        passport: 'Паспорт серияси/рақами',
        pinfl: 'ЖШШИР',
        id: 'ID',
        cardNumber: 'Карта(4 посл цифры)',
        datails: 'Детали',
        cashierName: 'Ф.И.О кассира',
        CASH: 'Наличные',
        TERMINAL: 'Терминал',
        PAYME: 'Payme',
        CLICK: 'Сlick',
        REFUND: 'Возврат',
        FREE: 'Бесплатно',
        UZCARD: 'UZCARD',
        HUMO: 'HUMO',
        BANK: 'BANK',
        EPOS: 'EPOS',
        THEORY_PAYMENT: 'Теоретический экзамен',
        PRACTICE_PAYMENT: 'Практический экзамен',
        examType: 'Тип экзамена',
      },
      StatusesPage: {
        categoryDeleted: 'Тоифа олиб ташланди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        statuses: 'Статуслар',
        order: 'Тартиб',
        name: 'Номи',
        titleLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        titleCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси) ',
        titleRu: 'Рус тилидаги номи',
        pass: 'Рухсатнома/бейжик',
        actions: 'Ҳаракатлар',
        wantDeleteStatus: 'Сиз ҳақиқатан ҳам статусни ўчирмоқчимисиз?',
      },
      PracticePenaltiesPage: {
        penaltiesDeleted: 'Жарима баллари ўчирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        penalties: 'Жарималар',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси) ',
        nameRu: 'Рус тилидаги номи',
        penaltyPoints: 'Жарима баллари',
        actions: 'Ҳаракатлар',
        code: 'Код',
        wantDeletePenalty: 'Сиз ҳақиқатан ҳам жарима балларини ўчирмоқчимисиз?',
      },
      PracticeExercisesPage: {
        exserciseDeleted: 'Машқ ўчирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        praticeExercises: 'Амалий машқлар',
        titleLat: 'Заголовок на узбекском (латиница)',
        titleCyr: 'Заголовок на узбекском (кириллица)',
        titleRu: 'Заголовок на русском',
        wantDeleteExercise: 'Сиз ҳақиқатан ҳам машқни ўчирмоқчимисиз?',
        code: 'Код',
      },
      PaymentPage: {
        payment: 'Тўлов',
        search: 'Қидирув',
        fio: 'Ф.И.Ш.',
        categories: 'Тоифа',
        statuses: 'Статус',
        certificate: 'Сертификат',
        printCertificate: 'Сертификатни чоп этиш',
        sum: 'Суммаси',
        allSum: 'Умумий суммаси',
        cash: 'Нақд',
        bankCard: 'Терминал',
        pay: 'Тўлаш',
        payDate: 'Тўлов санаси',
        paymentType: 'Тўлов турлари',
        error: 'Хато',
      },
      ErrorTypesPage: {
        name: 'Номи',
        createdAt: 'Яратилган санаси',
        actions: 'Ҳаракатлар',
        errorTypeDeleted: 'Хато тури ўчирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        errors: 'Хатолар',
      },
      //fix
      MotordromesPage: {
        motordromeDeleted: 'Автодром удален',
        error: 'Произошла ошибка, попробуйте позже',
        wantDeleteMotordrome: 'Вы действительно хотите удалить автодром?',
        motordromes: 'Автодромы',
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
      //fix
      SchoolPage: {
        tin: 'ИНН',
        schoolDeleted: 'Школа удалена',
        error: 'Произошла ошибка, попробуйте позже',
        wantDeleteSchool: 'Вы действительно хотите удалить школу?',
        search: 'Поиск',
        clear: 'Очистить',
        schools: 'Школы',
        enterName: 'Введите название для поиска',
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
      DocsPage: {
        docDeleted: 'Хужжат ўчирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        wantDeleteDoc: 'Сиз ҳақиқатан ҳам хужжатни ўчирмоқчимисиз?',
        docs: 'Хужжатлар',
        name: 'Номи',
        createdAt: 'Яратилган санаси',
        actions: 'Ҳаракатлар',
      },
      CreateVehicleTypePage: {
        fillFields: 'Катакларни тўлдиринг',
        createdVahicleType: 'Транспорт воситасининг тури яратилди',
        createCategory: 'Автомобильни ҳайдаш бўйича тоифани яратиш',
        name: 'Номини киритинг',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
      },
      //fix
      CreateStatusPage: {
        createStatus: 'Статус яратиш',
        type: 'Турини танланг',
        queue: 'Навбатни танланг',
        pass: 'Рухсатнома/бейжик',
        payment: 'Тўлов',
        name: 'Номи',
        titleLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        titleCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        titleRu: 'Рус тилидаги номи',
        order: 'Тартиб',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
        vehicleType: 'Тип транспортного средства',
      },
      //fix
      CreatePracticePenaltyPage: {
        createPenalty: 'Жарима балларини яратиш',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameRu: 'Рус тилидаги номи',
        scores: 'Баллар',
        cancel: 'Бекор қилиш',
        save: 'Сақлаш',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      //fix
      CreatePracticeExercisesPage: {
        createExercisse: 'Машқ яратиш',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameRu: 'Рус тилидаги номи',
        cancel: 'Бекор қилиш',
        save: 'Сақлаш',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      CreateErrorTypePage: {
        fillFields: 'Катакларни тўлдиринг',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        errorCreateSuccesed: 'Хато тури муваффақиятли яратилди',
        createError: 'Хато турини яратиш',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameRu: 'Рус тилидаги номи',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
      },
      UpdateMotordromePage: {
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameLat: 'Ўзбек тилидаги номи (лотин алифбоси)',
        nameRu: 'Рус тилидаги номи',
        conflictError: 'Автодром в данной очереди уже существует',
        selectQueue: 'Выберите очередь',
        updateMotordrome: 'Изменить автодром',
        fillFields: 'Катакларни тўлдиринг',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        updatedMotordrome: 'Автодром изменен',
        name: 'Название автодрома',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
        vehicleTypes: 'Транспорт воситалари',
      },
      //fix
      CreateMotordromePage: {
        conflictError: 'Автодром в данной очереди уже существует',
        selectQueue: 'Выберите очередь',
        fillFields: 'Катакларни тўлдиринг',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        motordromeCreatedSuccess: 'Автодром создан успешно',
        createMotordrome: 'Создать автодром',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Рус тилидаги номи',
        name: 'Название автодрома',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
        vehicleTypes: 'Транспорт воситалари',
      },
      //fix
      UpdateSchoolPage: {
        tin: 'ИНН',
        conflict: 'Школа уже существует',
        tinConflict: 'Школа с таким ИНН уже существует',
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        name: 'Название школы',
        save: 'Сохранить',
        back: 'Назад',
        updateSchool: 'Изменить школу',
        updatedSchool: 'Школа изменена',
      },
      //fix
      CreateSchoolPage: {
        tin: 'ИНН',
        conflict: 'Школа уже существует',
        fillFields: 'Катакларни тўлдиринг',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        schoolCreatedSuccess: 'Школа создана успешно',
        createSchool: 'Создать школу',
        name: 'Название школы',
        save: 'Сақлаш',
        back: 'Орқага қайтиш',
      },
      //fix
      CreateDocPage: {
        required: 'Обязательный/Не обязательный',
        nameConflict: 'Документ с таким названием уже существует',
        fillFields: 'Катакларни тўлдиринг',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        docCreateSuccesed: 'Хужжат муваффақиятли яратилди',
        createDoc: 'Хужжатни яратиш',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Рус тилидаги номи',
        name: 'Хужжатнинг номи',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
      },
      CreateCategoryPage: {
        fillFields: 'Катакларни тўлдиринг',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        categoryCreatSuccessed: 'Тоифа муваффақиятли яратилди',
        createdCategory: 'Тоифани яратиш',
        categoryName: 'Тоифа номи',
        nameCyr: 'Ўзбек тилидаги номи (Кирилл алифбоси)',
        nameLat: 'Название на узбекском (латиница)',
        nameRu: 'Рус тилидаги номи',
        order: 'Тартиб',
        selectDocuments: 'Хужжатларни танланг',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
        vehicleTypes: 'Транспорт воситалари',
      },
      //fix
      CreateApplicationPageByUser: {
        resident: 'Ўзбекистон Республикаси фуқароси',
        noResident: 'Хорижий давлат фуқароси',
        isForeigner:
          'Ўзбекистон Республикаси фуқароси / Хорижий давлат фуқароси',
        choiceBranch: 'Филиални танланг',
        residencePlace: 'Яшаш жойи',
        enterSchoolName: 'Автомактабнинг номини киритинг',
        notInList: 'Рўйхатда йўқ',
        choiceSchool: 'Автомактабни танланг',
        fillFields: 'Катакларни тўлдиринг',
        selectLanguage: 'Имтиҳонни ўтказиш тилини танланг',
        visitDateNotValid: 'Не верная дата визита в центр',
        visitDate: 'Марказга ташриф буюрган санаси',
        fillDocs: 'Заполните документы',
        selectCategory: 'Тоифани танланг',
        applicationSuccessCreated: 'Ариза муваффақиятли яратилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        vehicleType: 'Транспорт воситасинининг турини белгиланг',
        createApplication: 'Ариза яратиш',
        selectVehicleType: 'Транспорт воситасинининг турини танланг',
        documentList: 'Хужжатлар рўйхати',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
        applicationAlreadyExists: 'Бундай тоифали ариза мавжуд',
        time: 'Соат',
      },
      //fix
      CreateApplicationPage: {
        resident: 'Ўзбекистон Республикаси фуқароси',
        noResident: 'Хорижий давлат фуқароси',
        isForeigner:
          'Ўзбекистон Республикаси фуқароси / Хорижий давлат фуқароси',
        residencePlace: 'Место проживания',
        notInList: 'Нет в списке',
        enterSchoolName: 'Введите название автошколы',
        choiceSchool: 'Выберите автошколу',
        clear: 'Очистить',
        selectLanguage: 'Имтиҳонни ўтказиш тилини танланг',
        fillFields: 'Катакларни тўлдиринг',
        vehicleType: 'Транспорт воситасинининг турини белгиланг',
        uploadDocuments: 'Хужжатларни юкланг',
        applicationSuccessCreated: 'Ариза муваффақиятли яратилди',
        applicationAlreadyExists: 'Бундай тоифали ариза мавжуд',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        userNotFound: 'Фойдаланувчи топилмади',
        createApplication: 'Ариза яратиш',
        enterPhoneNumber: 'Фойдаланувчининг телефон рақамини киритинг',
        pinfl: 'ЖШШИР',
        passportSerial: 'Паспорт серияси',
        passportNumber: 'Паспорт рақами',
        selectCategory: 'Тоифани танланг',
        selectVehicleType: 'Транспорт тоифасининг турини танланг',
        userData: 'Фойдаланувчининг маълумотлари',
        firstName: 'Исми',
        lastName: 'Фамилияси',
        middleName: 'Отасининг исми',
        dateOfBirth: 'Туғилган санаси',
        documentList: 'Хужжатлар рўйхати',
        back: 'Орқага қайтиш',
        save: 'Сақлаш',
        passIssued: 'Пропуск оформлен',
        choiceCategory: 'Выберите категорию',
        enterPassport: 'Введите серию и номер паспорта',
        errorPinfl: 'ЖШШИР маълумотларида хатолик мавжуд',
        errorPhone: 'Телефон рақамида хатолик мавжуд',
      },
      CategoriesPage: {
        deletedCategory: 'Тоифа ўчирилди',
        error: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        wantDeleteCategory: 'Сиз ҳақиқатан ҳам тоифани ўчирмоқчимисиз?',
        categories: 'Тоифалар',
        name: 'Номи',
        createdAt: 'Яратилган санаси',
        actions: 'Ҳаракатлар',
      },
      CashPayment: {
        cashPayment: 'Нақд тўлов',
        fio: 'Ф.И.Ш.',
        passport: 'Паспорт серияси/рақами',
        pinfl: 'ЖШШИР',
        date: 'Тўлов санаси',
        sum: 'Тўлов суммаси',
        details: 'Тафсилотлар',
        amountEnclosed: 'Инкассация қилинган сумма',
        dateEnclosed: 'Инкассация қилинган сана',
        fioCashier: 'Ф.И.О кассира, принимающего оплату',
        idMachine: 'Тўлов қурилмасининг идентификация рақами',
      },
      BankCardPayment: {
        cardPayment: 'Банк картаси орқали тўлов',
        fio: 'Ф.И.Ш.',
        passport: 'Паспорт серияси/рақами',
        pinfl: 'ЖШШИР',
        date: 'Тўлов санаси',
        sum: 'Тўлов суммаси',
        details: 'Тафсилотлар',
        lastNumber: 'Банк картасининг охирги 4 рақами',
        fioCashier: 'Ф.И.О кассира, принимающего оплату',
        idMachine: 'Тўлов қурилмасининг идентификация рақами',
      },
      //fix
      ApplicationsPage: {
        operator: 'Оператор',
        branch: 'Филиал',
        application: 'Аризалар',
        clear: 'Тозалаш',
        search: 'Қидирув',
        fio: 'Ф.И.Ш.',
        phone: 'Телефон',
        appCode: 'Аризанинг коди',
        passCode: 'Бейжик рақами',
        status: 'Ҳолат',
        category: 'Тоифа',
        transportType: 'Транспорт воситасининг тури',
        updatedAt: 'Ўзгартиришлар киритилган санаси',
        actions: 'Ҳаракатлар',
        serial: 'Паспорт серияси',
        number: 'Рақами',
        pinfl: 'ЖШШИР',
        createdAt: 'Яратилган санаси',
        endDate: 'Тугаш санаси',
        firstName: 'Исми',
        lastName: 'Фамилияси',
        middleName: 'Отасининг исми',
        categories: 'Тоифа',
        vehicleTypes: 'Транспорт воситалари',
        statuses: 'Статус',
        creationType: 'Ариза тури',
      },
      auth: {
        notFound: 'Телефон рақами топилмади',
        enterOtpCode: 'СМС кодини киритинг',
        //fix
        passwordNotConfirmed: 'Пароллар бир-бирига мос эмас',
        passwordNotValid:
          'Парол узунлиги камида 6та белгидан иборат бўлиши керак',
        login: 'Кириш',
        phoneNumber: 'Телефон рақами',
        password: 'Пароль',
        forgotPassword: 'Парольни унутдингизми',
        confirmPassowrd: 'Парольни тасдиқланг',
        register: 'Рўйхатдан ўтиш',
        resetPassword: 'Парольни ўчириш',
        send: 'Юбориш',
        confirmOffer: 'Оммавий таклифни (офертани) тасдиқланг',
        enterCode: 'СМС-кодни киритинг',
        newPassword: 'Янги пароль',
        smsCodeReplay: 'Код ёзилган СМСни қайта юбориш',
        sendCodeToMobile: 'Рақамингизга код ёзилган СМС юборилди',
        sendSmsLater: 'СМСни қайта юбориш',
        sendSmsAgain: 'СМСни қайта юбориш',
        authSuccess: 'Авторизация мқваффақиятли ўтди',
        error: 'Маълумотлар тўғри тўлдирилганлигини текширинг',
        notValidLoginOrPassword: 'Нотўғри логин ёки пароль киритилган',
        tryLaterAgain: 'Хатолик юз берди. Бир оздан кейин уриниб кўринг',
        editedPassword: 'Пароль ўзгартирилди',
        registed: 'Рўйхатдан ўтиш',
        codeNotValid: 'Нотўғри СМС-код',
        registrationSuccess: 'Рўйхатдан муваффақиятли ўтилди',
        alreadyUser: 'Бундай фойдаланувчи мавжуд',
        repeatPassword: 'Парольни қайта теринг',
        offer: 'Оммавий таклиф',
        back: 'Орқага қайтиш',
        cancel: 'Бекор қилиш',
        sendCode: 'Кодни юбориш',
        checkCorrectData: 'Маълумотлар тўғри тўлдирилганлигини текширинг',
        passwordChanged: 'Пароль ўзгартирилди',
      },
      //fix
      updateQueuePage: {
        choiceBranch: 'Выберите филиал',
        choiceQueueType: 'Выберите тип очереди',
        queueUpdated: 'Очередь изменена',
        practice: 'Практика',
        theory: 'Теория',
        fillFields: 'Заполните поля',
        tryAgainLater: 'Произошла ошибка, попробуйте позже',
        updateQueue: 'Изменить очередь',
        nameOz: 'Название на узбекском (кирилица)',
        nameUz: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      //fix
      createQueuePage: {
        choiceBranch: 'Выберите филиал',
        choiceQueueType: 'Выберите тип очереди',
        queueCreated: 'Очередь создана успешно',
        practice: 'Практика',
        theory: 'Теория',
        fillFields: 'Заполните поля',
        tryAgainLater: 'Произошла ошибка, попробуйте позже',
        createQueue: 'Создать очередь',
        nameOz: 'Название на узбекском (кирилица)',
        nameUz: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      //fix
      carsPage: {
        filter: 'Фильтр',
        choiceMotordrome: 'Выберите автодром',
        search: 'Найти',
        number: 'Номер',
        model: 'Модель',
        category: 'Категория',
        cars: 'Автомобили',
      },
      //fix
      queuePage: {
        branches: 'Филиал',
        confirmDelete: 'Удалить из очереди?',
        confirmLogout: 'Вы действительно хотите выйти?',
        usersCount: 'Количество людей в очереди',
        classRoom: 'Кабинет',
        selectClassRoom: 'Выберите кабинет',
        emptyQueue: 'Очередь пуста',
        confirmDeleteQueue: 'Сиз ҳақиқатан ҳам ушбу навбатни ўчирмоқчимисиз?',
        selectQueue: 'Навбатни танланг',
        clearedQueue: 'Навбат тозаланди',
        clear: 'Тозалаш',
        enterCount: 'Сонини киритинг',
        power: 'Ишга тушириш',
        name: 'Номи',
        updateDate: 'Ўзгартиришлар киритилган санаси',
        actions: 'Ҳаракатлар',
        notAccept: 'Кириш рад этилди. Илтимос, қабулхонага мурожаат қилинг',
        passNotValid: 'Рухсатнома ҳақиқий эмас',
        deletedQueue: 'Навбат ўчирилди',
        queue: 'Навбат',
      },
    },
  },
  uz: {
    general: {
      instance: {
        noAccess: 'Kirish taqiqlangan',
        login: 'Tizimga kiring',
        uploadDocuments: 'Xujjatlarni yuklang',
      },
    },
    components: {
      Header: {
        prompt: 'Siz rostdan chiqib ketmoqchimisiz?',
      },
      //fix
      transactionModal: {
        replenished: 'Пополнение',
        writeOff: 'Списание',
        actionType: 'Действие',
        pay: 'Оплата',
        payme: 'Payme',
        click: 'Click',
        terminal: 'Терминал',
        cash: 'Наличные',
        title: 'Транзакции',
        transactionType: 'Тип',
        amount: 'Сумма',
        status: 'Статус',
        payDate: 'Дата оплаты',
        completed: 'Успешно',
        failed: 'Ошибка оплаты',
      },
      resultsModal: {
        seeApp: 'Посмотреть заявку',
      },
      newApplicationNotice: {
        newNotice: 'Yangi onlayn ariza',
        open: "Ko'rish",
        close: 'Закрыть',
      },
      passModal: {
        enterPassNumber: 'Beyjik raqamini kiriting',
        save: 'Saqlash',
      },
      pagination: {
        rowsPerPage: 'Sahifadagi soni',
      },
      //fix
      queueList: {
        car: 'Avtomobil',
        classRoom: 'Auditoriya',
        choiceCar: 'Avtomobilni tanlang',
        call: 'Chaqirish',
        cancel: 'Bekor qilish',
        delete: "O'chirish",
        category: 'Toifa',
      },
      //fix
      TimeLine: {
        tests: 'Test-sinovlar',
        right: "To'g'ri",
        wrong: "Noto'g'ri",
        total: "Jami yig'ilgan ballar",
        startDate: 'Boshlash sanasi',
        attempts: 'Urinishlar',
        carNumber: 'Mashina raqami',
        penaltyPoints: 'Jarima ballari',
      },
      //fix
      Sidebar: {
        reports: 'Protokollar',
        schools: 'Maktablar',
        enterName: 'Qidirish uchun nom kiriting',
        control: 'Navbatni boshqarish',
        classRooms: 'Kabinet',
        cars: 'Avtomobillar',
        queue: 'Navbat',
        users: 'Foydalanuvchilar',
        categories: 'Toifalar',
        docs: 'Xujjatlar',
        applications: 'Arizalar',
        statuses: 'Statuslar',
        practiceCategory: 'Transport vositalari turlari',
        payment: "To'lov",
        passedExam: "Imtihondan o'tganlar",
        cashPayment: "Naqd to'lov",
        userId: 'Foydalanuvchini aniqlash',
        visitLogs: "Tashriflar ro'yxati",
        motordromes: 'Автодром',
        errors: 'Xatolar',
        transactions: 'Tranzaksiyalar',
        verrifyApps: 'Haqiqiyligi tasdiqlangan arizalar',
        generatReport: 'Umumiy hisobot',
        generalStatistics: 'Статистика отчет',
        schoolStatistics: 'Maktablar statistikasi',
        gaiSchoolsReport: "YHXBBning maktablar bo'yicha hisoboti",
        sumarry: 'Xulosa',
        appeals: 'Apellyatsiya',
        report: 'Raportlar',
        vising: 'Vizing',
        requestForChangeData: "Ma'lumotlarni o'zgartirish bo'yicha so'rovlar",
        certificates: 'Sertifikatlar',
        certificatesHistory: 'История сертификата',
        calendar: 'Taqvim',
        fines: 'Jarimalar',
        reception: 'Qabul',
        security: 'Xavfsizlik',
        accounting: 'Buxgalteriya',
        cash: 'Kassa',
        statistics: 'Statistika',
        integrationWithGai: 'Интеграция с гаи',
      },
      CategoryStatuses: {
        statusDeleted: "Status o'chirilgan",
        erorrTryAgain: "Xatolik yuz berdi. Yana bir bor urinib ko'ring",
        wantToRemove: "Siz haqiqatan ham toifani statusini o'chirmoqchimisiz?",
        add: "Toifani statusini qo'shish",
        status: 'Status',
        successStatus: 'Muvaffaqiyatli natijani statusi',
        failureStatus: 'Muvaffaqiyatsiz natijani statusi',
        order: 'Tartib',
        start: 'Boshlandi',
        finished: 'Tugadi',
        createdAt: 'Yaratilgan sanasi',
        actions: 'Harakatlar',
      },
      Confirmation: {
        cancel: 'Bekor qilish',
        success: 'Tasdiqlash',
      },
      FileUploader: {
        fileSize: 'Fayl hajmi juda katta',
        selectDocument: 'Xujjatni tanlash',
        selectAnotherDocument: 'Boshqa xujjatni tanlash',
        scanning: 'Skanerlash',
      },
      FileUploads: {
        mainPassport: 'Pasport',
        photo: 'Fotosurat',
        passport: 'Pasport/ID karta (propiska bilan)',
        employerReferral: "Ish beruvchining yo'llanmasi",
        replacement:
          "Yagona namunadagi guvohnoma yoki uzluksiz haydovchilik mehnat faoliyati 10 yildan ortiq bo'lgan «Á» va «Ñ» toifali haydovchilik guvohnomasi bo'lgan shaxslarning haydovchilik guvohnomasini «BE» va «CE» toifaga almashtirish haydovchining mehnat daftarchasidan ko'chirma asosida",
        driverLicence_1: 'Milliy haydovchilik guvohnomasi-1',
        driverLicence_2: 'Milliy haydovchilik guvohnomasi-2',
        direction: "Ichki ishlar vakolatli organining yo'llanmasi",
        honey083_1: "083-shakldagi tibbiy ma'lumotnoma-1",
        honey083_2: "083-shakldagi tibbiy ma'lumotnoma-2",
        diplomaAnnex:
          "Reja asosida fanlarni o'zlashtirilganligi to'g'risida diplom va unga ilova",
        nationalDriversLicence:
          'Milliy haydovchilik guvohnomasi («Ñ», «Á» va «Ñ», «Ä», «Ñ» i «Ä», «SE» va (yoki) «DE»)',
        certificateSingleSample: 'Yagona namunadagi Guvohnoma',
      },
      Footer: {
        address: 'Toshkent shahri, Yangihayot tumani, Fayzli Mahallasi, 87',
        copyRight: '2022 barcha huquqlar himoyalangan',
      },
      CreateCategoryStatuses: {
        fillFields: "Kataklarni to'ldiring",
        createdSuccess: 'Toifa statusi muvaffaqiyatli yaratilgan',
        alreadyExistsDefault:
          '«Dastlabki» statusi bilan toifa statusi allaqachon mavjud',
        alreadyExistsFinish:
          '«Tugatilgan» statusi bilan toifa statusi allaqachon mavjud',
        errorTryAgain: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        createStatusCategory: 'Toifa uchun status yaratish',
        selectStatus: 'Statusni tanlang',
        successStatus: 'Muvaffaqiyatli natijani statusi',
        failureStatus: 'Muvaffaqiyatsiz natijani statusi',
        workDays: 'Qayta urinishdan oldin ish kunlari soni',
        order: 'Tartib',
        defaultStatus: '«Dastlabki» status',
        finished: 'Tugadi',
        cancel: 'Bekor qilish',
        save: 'Saqlash',
      },
      UpdateCategoryStatuses: {
        fillFields: "Kataklarni to'ldiring",
        updateSuccess: "Toifa statusi o'zgartirilgan",
        errorTryAgain: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        updateStatusCategory: "Toifa uchun status o'zgartirish",
        selectStatus: 'Statusni tanlang',
        successStatus: 'Muvaffaqiyatli natijani statusi',
        failureStatus: 'Muvaffaqiyatsiz natijani statusi',
        cost: 'Narh',
        workDays: 'Qayta urinishdan oldin ish kunlari soni',
        order: 'Tartib',
        defaultStatus: '«Dastlabki» status',
        finished: 'Tugadi',
        cancel: 'Bekor qilish',
        save: 'Saqlash',
        alreadyExistsDefault:
          '«Dastlabki» statusi bilan toifa statusi allaqachon mavjud',
        alreadyExistsFinish:
          '«Tugatilgan» statusi bilan toifa statusi allaqachon mavjud',
      },
      TokenModal: {
        copy: 'Nusha olindi',
        refresh: 'Yangilash',
      },
      UserModal: {
        userInfo: "Foydalanuvchi haqida ma'lumot",
        FIO: 'F.I.Sh.',
        dateOfBirth: "Tug'ilgan sanasi",
        passport: 'Pasport seriyasi/raqami',
        pinfl: 'JShShIR',
        phone: 'Telefon raqami',
        cancel: 'Bekor qilish',
        successUser: 'Foydalanuvchini tasdiqlash',
        name: 'Nomi',
        createdAt: 'Yaratilgan sanasi',
        actions: 'Harakatlar',
      },
    },
    pages: {
      //fix
      ReportPage: {
        //fix
        reportHistory: 'История протокола',
        FIO: 'ФИО',
        category: 'Категория',
        school: 'Школа',
        theryAttempt: 'Попытки теория',
        thoryScore: 'Балы теория',
        practiceAttempt: 'Поптыки практика',
        practiceScore: 'Сдал/Не сдал',
        passed: 'Сдал',
        notPassed: 'Не сдал',
        certificateNumber: 'Номер сертификата',
        print: 'Распечатать',
      },
      ReportsPage: {
        enterDate: 'Введите дату',
        count: 'Количество',
        actions: 'Действие',
        createdAt: 'Дата создания',
        dayNumber: 'Число генерации протокола',
        reports: 'Протоколы',
        generation: 'Сгенерировать',
        name: 'Название',
        date: 'Дата',
      },
      SummaryPage: {
        branch: 'Filial',
        from: 'С',
        to: 'По',
        branches: 'Filiallar',
        summary: 'Xulosa',
        application: 'Ariza',
        clear: 'Tozalash',
        search: 'Qidirish',
        fio: 'F.I.Sh',
        appCode: 'Ariza kodi',
        status: 'Status',
        createdAt: 'Yaratilgan sana',
        firstName: 'Ism',
        lastName: 'Familiya',
        middleName: 'Otasining ismi',
        categories: 'Kategoriya',
        vehicleTypes: 'Avtomobil turi',
        oldStatus: 'Oldingi holat',
        newStatus: 'Yangi holat',
        attempt: 'Urinish',
        attempts: 'Urinishlar',
        firstAttempt: "Birinchi urinib ko'rish",
        retake: 'Qayta olish',
        passport: 'Seriya/pasport raqami',
        phone: 'Telefon',
        result: 'Natija',
        true: 'Muvaffaqiyatli',
        false: 'Muvaffaqiyatsiz',
      },
      UpdateClassRoomPage: {
        choiceBranch: 'Выберите филиал',
        nameError: 'Название кабинета уже существует',
        fillFields: 'Заполните поля',
        updatedClassRoom: 'Кабинет изменен',
        updateClassRoom: 'Изменить кабинет',
        enterName: 'Введите название',
        back: 'Назад',
        save: 'Сохранить',
      },
      //fix
      CreateClassRoomPage: {
        choiceBranch: 'Выберите филиал',
        nameError: 'Название кабинета уже существует',
        fillFields: 'Заполните поля',
        createdClassRoom: 'Кабинет создан',
        createClassRoom: 'Создать кабинет',
        name: 'Название',
        back: 'Назад',
        save: 'Сохранить',
      },
      //fix
      ClassRoomsPage: {
        classRooms: 'Кабинеты',
        deleted: 'Кабинет удален',
        wantDeleteClassRoom: 'Вы действительно хотите удалить этот кабинет?',
        updatedDate: 'Дата изменения',
        actions: 'Действия',
        name: 'Название',
        classRoom: 'Кабинет',
      },
      ApplicationForGai: {
        fio: 'F.I.Sh.',
        passport: 'Pasport',
        dateOfBirth: "Tug'ilgan sanasi",
        phone: 'Telefon',
        vehicleType: 'Transport vositasining turi',
      },
      ApplicationsForGai: {
        application: 'Arizalar',
        clear: 'Tozalash',
        search: 'Qidiruv',
        fio: 'F.I.Sh.',
        phone: 'Telefon',
        appCode: 'Arizaning kodi',
        passCode: 'Beyjik raqami',
        status: 'Status',
        category: 'Toifa',
        transportType: 'Transport vositasining turi',
        updatedAt: "O'zgartirishlar kiritilgan sanasi",
        actions: 'Harakatlar',
        serial: 'Seriyasi',
        number: 'Raqami',
        pinfl: 'JShShIR',
        createdAt: 'Yaratilgan sanasi',
        endDate: 'Tugash sanasi',
        firstName: 'Ismi',
        lastName: 'Familiyasi',
        middleName: 'Otasining ismi',
        categories: 'Toifa',
        vehicleTypes: 'Transport vositalari',
        statuses: 'Status',
      },
      VisitLogsPage: {
        visitLogs: "Tashriflar ro'yxati",
        date: 'Sana',
        search: 'Qidiruv',
        clear: 'Tozalash',
        fio: 'F.I.Sh.',
        dateOfBirth: "Tug'ilgan sanasi",
        passport: 'Pasport seriyasi/raqami',
        appCategory: 'Arizaning turi',
        createdAt: 'Yaratilgan sanasi',
        status: 'Status',
      },
      VehicleTypesPage: {
        deleted: "Muvaffaqiyatli o'chirildi",
        wantDeleteVehicle:
          "Siz haqiqatan ham transport vositasini o'chirishni xohlaysizmi?",
        vehicleTypes: 'Transport vositalari',
        name: 'Nomi',
        actions: 'Harakatlar',
      },
      UsersPage: {
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        users: 'Foydalanuvchilar',
        serial: 'Seriyasi',
        number: 'Raqami',
        firstName: 'Ismi',
        lastName: 'Familiyasi',
        middleName: 'Otasining ismi',
        search: 'Qidiruv',
        clear: 'Tozalash',
        passport: 'Pasport seriyasi/raqami',
        pinfl: 'JShShIR',
        fio: 'F.I.Sh.',
        phone: 'Telefon raqami',
        integrated: 'Itegratsiya qilingan',
        role: 'Roli',
        verrified: 'Tasdiqlangan',
        status: 'Status',
        actions: 'Harakatlar',
      },
      UserIdentificationPage: {
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        applicationNotFound: 'Ariza topilmadi',
        userNotActive: 'Foydalanuvchi faol emas',
        statusError: 'Status xatosi',
        appNotVerrified: 'Ariza tasdiqlanmagan',
        fio: 'F.I.Sh.',
        dateOfBirth: "Tug'ilgan sanasi",
        phone: 'Telefon raqami',
        passport: 'Pasport seriyasi/raqami',
        pinfl: 'JShShIR',
        appStatus: 'Arizaning statusi',
        appCategory: 'Arizaning turi',
        failureDays:
          "Imtihonni qayta topshirish uchun avvalgi imtihon topshirilgan kunidan 7 ish kuni o'tishi kerak",
        success: 'Muvaffaqiyatli',
      },
      UpdateVehicleTypePage: {
        fillFields: "Kataklarni to'ldiring",
        vehicletypeUpdated: "Transport vositasi o'zgartirildi",
        updateVehicleType: "Transport vositasini o'zgartirish",
        enterName: 'Nomini kiriting',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
      },
      //fix
      UpdateUserPage: {
        motordrome: 'Автодром',
        phoneError: 'Bunday telefon bilan foydalanuvchi mavjud',
        passportError: 'Bunday pasport bilan foydalanuvchi mavjud',
        pinflError: 'Bunday JShShIR bilan foydalanuvchi mavjud',
        updateUser: 'Foydalanuvchini yangilash',
        serial: 'Seriyasi',
        number: 'Raqami',
        phone: 'Telefon raqami',
        role: 'Roli',
        firstName: 'Ismi',
        lastName: 'Familiyasi',
        middleName: 'Otasining ismi',
        dateOfBirth: "Tug'ilgan sanasi",
        pinfl: 'JShShIR',
        getToken: 'Tokenni qabul qilish',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
      },
      //fix
      UpdateStatusPage: {
        updateStatus: 'Statusni yangilash',
        type: 'Turini tanlang',
        queue: 'Navbatni tanlang',
        pass: 'Beyjik',
        pay: "To'lov",
        name: 'Nomi',
        titleLat: "O'zbek tilidagi sarlavha (lotin alifbosi)",
        titleCyr: "O'zbek tilidagi sarlavha (Kirill alifbosi)",
        titleRu: 'Rus tilidagi sarlavha',
        order: 'Tartib',
        back: 'Orqaga qaytish',
        update: 'Yangilash',
        vehicleType: 'Transport vositalari',
      },
      //fix
      UpdatePracticePenaltyPage: {
        updatePenalty: 'Jarima ballarini yangilash',
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        points: 'Ballar',
        cancel: 'Bekor qilish',
        save: 'Saqlash',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      //fix
      UpdatePracticeExercisesPage: {
        updateExercise: 'Mashqni yangilash',
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        cancel: 'Bekor qilish',
        save: 'Saqlash',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      UpdateErrorTypePage: {
        fillFields: "Kataklarni to'ldiring",
        errorTypeChanged: "Xato turi o'zgartirildi",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        updateErrorType: "Xato turini o'zgartirish",
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
      },
      UpdateDocPage: {
        required: 'Обязательный/Не обязательный',
        nameConflict: 'Документ с таким названием уже существует',
        fillFields: "Kataklarni to'ldiring",
        docUpdated: "Xujjat o'zgartirildi",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        updateDocument: 'Xujjatni tahrirlash',
        docName: 'Xujjat nomi',
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
      },
      //fix
      UpdateCategoryPage: {
        fillFields: "Kataklarni to'ldiring",
        categoryUpdated: "Toifasi o'zgartirildi",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        updateCategory: 'Toifani tahrirlash',
        data: "Ma'lumotlar",
        statuses: 'Statuslar',
        categoryName: 'Toifa nomi',
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        order: 'Tartib',
        selectDocuments: 'Xujjatni tanlang',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
        vehicleTypes: 'Transport vositalari',
      },
      ApplicationHistoryPage: {
        branch: 'Filial',
        actions: 'Harakatlar',
        more: 'Batafsil',
        applicationHistory: 'Ariza tarixi',
        history: 'Tarix',
        result: 'Natija',
        sucess: "To'g'ri",
        fail: "Noto'g'ri",
        answer: 'Javob',
        question: 'Savol',
        ticketNumber: 'Bilet raqami',
        createdDate: 'Yaratilgan sana',
        statuses: 'Statuslar',
        transactions: 'Tranzaksiyalar',
        results: 'Natijalar',
        oldStatus: 'Oldingi holat',
        nextStatus: 'Keyingi holat',
        currentStatus: 'Joriy holat',
        category: 'Toifasi',
      },
      UpdateApplicationPage: {
        resident: 'O‘zbekiston Respublikasi fuqarosi',
        noResident: 'Xorijiy davlat fuqarosi',
        isForeigner:
          'O‘zbekiston Respublikasi fuqarosi / Xorijiy davlat fuqarosi',
        time: 'Soat',
        branch: 'Filial',
        choiceBranch: 'Filialni tanlang',
        residencePlace: 'Yashash joyi',
        more: 'Подробнее о заявке',
        uploadDocuments: 'Заполните документы',
        notInList: "Ro'yxatda yo'q",
        enterSchoolName: 'Avtomaktabninig nomini kiriting',
        choiceSchool: 'Avtomaktabni tanlang',
        selectLanguage: "Imtihonni o'tkazish tilini tanlang",
        visitDateNotValid: 'Не верная дата визита в центр',
        visitDate: 'Markazga tashrif buyurgan sanasi',
        verification: 'Верифицированный пользователь',
        confirmedApplication: 'Ariza tasdiqlangan',
        printCertificate: 'Sertifikatni chop etish',
        correctPhone: "Telefon raqamini to'g'ri kiriting",
        userNotReceived: "Foydalanuvchining ma'lumotlari qabul qilinmadi",
        fillFields: "Kataklarni to'ldiring",
        vehicleType: 'Transport vositasining turini belgilang',
        applicationsUpdated: "Ariza o'zgartirildi",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        canceledApplication: 'Arizani bekor qiling',
        passportConflict: 'Bunday pasport bilan foydalanuvchi mavjud',
        applicationRejected: 'Ariza rad etildi',
        application: 'Ariza',
        updateApplication: "Arizani o'zgartirish",
        cancelApp: 'Arizani bekor qilish',
        save: 'Saqlash',
        appData: "Arizaning ma'lumotlari",
        transport: 'Transport vositasi',
        userData: "Foydalanuvchining ma'lumotlari",
        userPhone: 'Foydalanuvchining telefon raqamini kiriting',
        passportSeries: 'Pasport seriyasi',
        passportNumber: 'Pasport raqami',
        pinfl: 'JShShIR',
        firstName: 'Ismi',
        lastName: 'Familiyasi',
        middleName: 'Otasining ismi',
        dateOfBirth: "Tug'ilgan sanasi",
        back: 'Orqaga qaytish',
        phone: 'Telefon raqami',
        category: 'Toifasi',
        transportType: 'Transport vositasining turi',
        reasonsRejectedApp: 'Arizani rad etish sabablari',
        docsList: "Xujjatlarning ro'yxati",
        passIssue: 'Beyjikni rasmiylashtirish',
        selectCategoryAndTryAgain: "Toifani tanlab qayta urinib ko'ring",
        passIssued: 'Beyjik rasmiylashtirildi',
        confirmApplication: 'Arizani tasdiqlash',
        errorTryLater: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        conflictPinfl: 'Пользователь с таким ПИНФЛ уже существует',
        conflictSerialNumber:
          'Bunday seriya va pasport raqamiga ega foydalanuvchi allaqachon mavjud',
        conflictPhone:
          'Ushbu telefon raqamiga ega foydalanuvchi allaqachon mavjud',
      },
      TransactionsPage: {
        transactions: 'Tranzaksiyalar',
        fio: 'F.I.Sh.',
        paymentDate: "To'lov sanasi",
        paymentType: "To'lov turlari",
        sum: 'Summasi',
        passport: 'Pasport seriyasi/raqami',
        pinfl: 'JShShIR',
        id: 'ID',
        cardNumber: 'Карта(4 посл цифры)',
        datails: 'Детали',
        cashierName: 'Ф.И.О кассира',
        CASH: 'Наличные',
        TERMINAL: 'Терминал',
        PAYME: 'Payme',
        CLICK: 'Сlick',
        FREE: 'Бесплатно',
        REFUND: 'Возврат',
        UZCARD: 'UZCARD',
        HUMO: 'HUMO',
        BANK: 'BANK',
        EPOS: 'EPOS',
        THEORY_PAYMENT: 'Теоретический экзамен',
        PRACTICE_PAYMENT: 'Практический экзамен',
        examType: 'Тип экзамена',
      },
      StatusesPage: {
        categoryDeleted: 'Toifa olib tashlandi',
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        statuses: 'Statuslar',
        order: 'Tartib',
        name: 'Nomi',
        titleLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        titleCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        titleRu: 'Rus tilidagi nomi',
        pass: 'Beyjik',
        actions: 'Harakatlar',
        wantDeleteStatus: "Siz haqiqatan ham statusni o'chirmoqchimisiz?",
      },
      PracticePenaltiesPage: {
        penaltiesDeleted: "Jarima ballari o'chirildi",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        penalties: 'Jarimalar',
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        penaltyPoints: 'Jarima ballari',
        actions: 'Harakatlar',
        code: 'Код',
        wantDeletePenalty:
          "Siz haqiqatan ham jarima ballarini o'chirmoqchimisiz?",
      },
      PracticeExercisesPage: {
        exserciseDeleted: "Mashq o'chirildi",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        praticeExercises: 'Amaliy mashqlar',
        titleLat: "O'zbek tilidagi sarlavha (lotin alifbosi)",
        titleCyr: "O'zbek tilidagi sarlavha (Kirill alifbosi)",
        titleRu: 'Rus tilidagi sarlavha',
        wantDeleteExercise: "Siz haqiqatan ham mashqni o'chirmoqchimisiz?",
        code: 'Код',
      },
      PaymentPage: {
        payment: "To'lov",
        search: 'Qidiruv',
        fio: 'F.I.Sh.',
        categories: 'Toifa',
        statuses: 'Status',
        certificate: 'Sertifikat',
        printCertificate: 'Sertifikatni chop etish',
        sum: 'Summasi',
        allSum: 'Umumiy summasi',
        cash: 'Naqd',
        bankCard: 'Terminal',
        pay: "To'lash",
        payDate: "To'lov sanasi",
        paymentType: "To'lov turlari",
        error: 'Xato',
      },
      ErrorTypesPage: {
        name: 'Nomi',
        createdAt: 'Yaratilgan sanasi',
        actions: 'Harakatlar',
        errorTypeDeleted: "Xato turi o'chirildi",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        errors: 'Xatolar',
      },
      MotordromesPage: {
        motordromeDeleted: 'Автодром удален',
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        wantDeleteMotordrome: 'Вы действительно хотите удалить автодром?',
        motordromes: 'Автодромы',
        name: 'Nomi',
        createdAt: 'Yaratilgan sanasi',
        actions: 'Harakatlar',
      },
      SchoolPage: {
        tin: 'ИНН',
        schoolDeleted: 'Школа удалена',
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        wantDeleteSchool: 'Вы действительно хотите удалить школу?',
        search: 'Поиск',
        clear: 'Очистить',
        schools: 'Школы',
        enterName: 'Введите название для поиска',
        name: 'Название',
        createdAt: 'Дата создания',
        actions: 'Действия',
      },
      DocsPage: {
        docDeleted: "Xujjat o'chirildi",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        wantDeleteDoc: "Siz haqiqatan ham xujjatni o'chirmoqchimisiz?",
        docs: 'Xujjatlar',
        name: 'Nomi',
        createdAt: 'Yaratilgan sanasi',
        actions: 'Harakatlar',
      },
      CreateVehicleTypePage: {
        fillFields: "Kataklarni to'ldiring",
        createdVahicleType: 'Transport vositasining turi yaratildi',
        createCategory: "Avtomobilni haydash bo'yicha toifani yaratish",
        name: 'Nomini kiriting',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
      },
      CreateStatusPage: {
        createStatus: 'Status yaratish',
        type: 'Turini tanlang',
        queue: 'Navbatni tanlang',
        pass: 'Beyjik',
        payment: "To'lov",
        name: 'Nomi',
        titleLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        titleCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        titleRu: 'Rus tilidagi nomi',
        order: 'Tartib',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
        vehicleType: 'Transport vositalari',
      },
      CreatePracticePenaltyPage: {
        createPenalty: 'Jarima ballarini yaratish',
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        scores: 'Ballar',
        cancel: 'Bekor qilish',
        save: 'Saqlash',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      CreatePracticeExercisesPage: {
        createExercisse: 'Mashq yaratish',
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        cancel: 'Bekor qilish',
        save: 'Saqlash',
        code: 'Код',
        codeError: 'Данный код уже существует',
      },
      CreateErrorTypePage: {
        fillFields: "Kataklarni to'ldiring",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        errorCreateSuccesed: 'Xato turi muvaffaqiyatli yaratildi',
        createError: 'Xato turini yaratish',
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
      },
      UpdateMotordromePage: {
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        conflictError: 'Автодром в данной очереди уже существует',
        selectQueue: 'Выберите очередь',
        updateMotordrome: 'Изменить автодром',
        fillFields: "Kataklarni to'ldiring",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        updatedMotordrome: 'Автодром изменен',
        name: 'Название автодрома',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
        vehicleTypes: 'Transport vositalari',
      },
      CreateMotordromePage: {
        conflictError: 'Автодром в данной очереди уже существует',
        selectQueue: 'Выберите очередь',
        fillFields: "Kataklarni to'ldiring",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        motordromeCreatedSuccess: 'Автодром создан успешно',
        createMotordrome: 'Создать автодром',
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        name: 'Название автодрома',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
        vehicleTypes: 'Transport vositalari',
      },
      UpdateSchoolPage: {
        tin: 'ИНН',
        conflict: 'Школа уже существует',
        tinConflict: 'Школа с таким ИНН уже существует',
        fillFields: 'Заполните поля',
        error: 'Произошла ошибка, попробуйте позже',
        name: 'Название школы',
        save: 'Сохранить',
        back: 'Назад',
        updateSchool: 'Изменить школу',
        updatedSchool: 'Школа изменена',
      },
      CreateSchoolPage: {
        tin: 'ИНН',
        conflict: 'Школа уже существует',
        fillFields: "Kataklarni to'ldiring",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        schoolCreatedSuccess: 'Школа создана успешно',
        createSchool: 'Создать школу',
        name: 'Название школы',
        save: 'Saqlash',
        back: 'Orqaga qaytish',
      },
      CreateDocPage: {
        required: 'Обязательный/Не обязательный',
        nameConflict: 'Документ с таким названием уже существует',
        fillFields: "Kataklarni to'ldiring",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        docCreateSuccesed: 'Xujjat muvaffaqiyatli yaratildi',
        createDoc: 'Xujjatni yaratish',
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        name: 'Xujjatning nomi',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
      },
      CreateCategoryPage: {
        fillFields: "Kataklarni to'ldiring",
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        categoryCreatSuccessed: 'Toifa muvaffaqiyatli yaratildi',
        createdCategory: 'Toifani yaratish',
        categoryName: 'Toifa nomi',
        nameCyr: "O'zbek tilidagi nomi (Kirill alifbosi)",
        nameLat: "O'zbek tilidagi nomi (lotin alifbosi)",
        nameRu: 'Rus tilidagi nomi',
        order: 'Tartib',
        selectDocuments: 'Xujjatni tanlang',
        back: 'Orqaga qaytish',
        save: 'Saqlash',
        vehicleTypes: 'Transport vositalari',
      },
      CreateApplicationPageByUser: {
        resident: 'O‘zbekiston Respublikasi fuqarosi',
        noResident: 'Xorijiy davlat fuqarosi',
        isForeigner:
          'O‘zbekiston Respublikasi fuqarosi / Xorijiy davlat fuqarosi',
        choiceBranch: 'Filialni tanlang',
        residencePlace: 'Yashash joyi',
        enterSchoolName: 'Avtomaktabninig nomini kiriting',
        notInList: "Ro'yxatda yo'q",
        choiceSchool: 'Avtomaktabni tanlang',
        fillFields: "Kataklarni to'ldiring",
        selectLanguage: "Imtihonni o'tkazish tilini tanlang",
        visitDateNotValid: 'Не верная дата визита в центр',
        visitDate: 'Markazga tashrif buyurgan sanasi',
        fillDocs: 'Заполните документы',
        selectCategory: 'Toifani tanlang',
        applicationSuccessCreated: 'Ariza muvaffaqiyatli yaratildi',
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        vehicleType: 'Transport vositasinining turini belgilang',
        createApplication: 'Ariza yaratish',
        selectVehicleType: 'Transport vositasinining turini tanlang',
        documentList: "Xujjatlar ro'yxati",
        back: 'Orqaga qaytish',
        save: 'Saqlash',
        applicationAlreadyExists: 'Bunday toifali ariza mavjud',
        time: 'Soat',
      },
      CreateApplicationPage: {
        resident: 'O‘zbekiston Respublikasi fuqarosi',
        noResident: 'Xorijiy davlat fuqarosi',
        isForeigner:
          'O‘zbekiston Respublikasi fuqarosi / Xorijiy davlat fuqarosi',
        residencePlace: 'Место проживания',
        notInList: 'Нет в списке',
        enterSchoolName: 'Введите название автошколы',
        choiceSchool: 'Выберите автошколу',
        clear: 'Очистить',
        selectLanguage: "Imtihonni o'tkazish tilini tanlang",
        fillFields: "Kataklarni to'ldiring",
        vehicleType: 'Transport vositasinining turini belgilang',
        uploadDocuments: 'Xujjatlarni yuklang',
        applicationSuccessCreated: 'Ariza muvaffaqiyatli yaratildi',
        applicationAlreadyExists: 'Bunday toifali ariza mavjud',
        error: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        userNotFound: 'Foydalanuvchi topilmadi',
        createApplication: 'Ariza yaratish',
        enterPhoneNumber: 'Foydalanuvchining telefon raqamini kiriting',
        pinfl: 'JShShIR',
        passportSerial: 'Pasport seriyasi',
        passportNumber: 'Pasport raqami',
        selectCategory: 'Toifani tanlang',
        selectVehicleType: 'Transport vositasinining turini tanlang',
        userData: "Foydalanuvchining ma'lumotlari",
        firstName: 'Ismi',
        lastName: 'Familiyasi',
        middleName: 'Otasining ismi',
        dateOfBirth: "Tug'ilgan sanasi",
        documentList: "Xujjatlar ro'yxati",
        back: 'Orqaga qaytish',
        save: 'Saqlash',
        passIssued: 'Пропуск оформлен',
        choiceCategory: 'Toifani tanlang',
        enterPassport: 'Введите серию и номер паспорта',
        errorPinfl: "JSHSHIR ma'lumotlarida xatolik mavjud",
        errorPhone: 'Telefon raqamida xatolik mavjud',
      },
      CategoriesPage: {
        deletedCategory: "Toifa o'chirildi",
        error: 'Xatolik yuz berdi',
        wantDeleteCategory: "Siz haqiqatan ham toifani o'chirmoqchimisiz?",
        categories: 'Toifalar',
        name: 'Nomi',
        createdAt: 'Yaratilgan sanasi',
        actions: 'Harakatlar',
      },
      CashPayment: {
        cashPayment: "Naqd to'lov",
        fio: 'F.I.Sh.',
        passport: 'Pasport seriyasi/raqami',
        pinfl: 'JShShIR',
        date: "To'lov sanasi",
        sum: "To'lov summasi",
        details: 'Tafsilotlar',
        amountEnclosed: 'Inkassatsiya qilingan summa',
        dateEnclosed: 'Inkassatsiya qilingan sana',
        fioCashier: "To'lovni qabul qilgan kassirning F.I.Sh.",
        idMachine: "To'lov qurilmasining identifikatsiya raqami",
      },
      BankCardPayment: {
        cardPayment: "Bank kartasi orqali to'lov",
        fio: 'F.I.Sh.',
        passport: 'Pasport seriyasi/raqami',
        pinfl: 'JShShIR',
        date: "To'lov sanasi",
        sum: "To'lov summasi",
        details: 'Tafsilotlar',
        lastNumber: 'Последние 4 цифры банк карты',
        fioCashier: "To'lovni qabul qilgan kassirning F.I.Sh.",
        idMachine: "To'lov qurilmasining identifikatsiya raqami",
      },
      ApplicationsPage: {
        operator: 'Operator',
        branch: 'Filial',
        application: 'Arizalar',
        clear: 'Tozalash',
        search: 'Qidiruv',
        fio: 'F.I.Sh.',
        phone: 'Telefon',
        appCode: 'Arizaning kodi',
        passCode: 'Beyjik raqami',
        status: 'Holat',
        category: 'Toifa',
        transportType: 'Transport vositasining turi',
        updatedAt: "O'zgartirishlar kiritilgan sanasi",
        actions: 'Harakatlar',
        serial: 'Seriyasi',
        number: 'Raqami',
        pinfl: 'JShShIR',
        createdAt: 'Yaratilgan sanasi',
        endDate: 'Tugash sanasi',
        firstName: 'Ismi',
        lastName: 'Familiyasi',
        middleName: 'Otasining ismi',
        categories: 'Toifa',
        vehicleTypes: 'Transport vositalari',
        statuses: 'Status',
        creationType: 'Ariza turi',
      },
      auth: {
        notFound: 'Telefon raqami topilmadi',
        enterOtpCode: 'SMS kodini kiriting',
        passwordNotConfirmed: 'Пароли не совпадают',
        passwordNotValid:
          "Parol uzunligi kamida 6ta belgidan iborat bo'lishi kerak",
        login: 'Kirish',
        phoneNumber: 'Telefon raqami',
        password: 'Parol',
        forgotPassword: 'Parolni unutdingizmi',
        confirmPassowrd: 'Parolni tasdiqlang',
        register: "Ro'yxatdan o'tish",
        resetPassword: "Parolni o'chirish",
        send: 'Yuborish',
        confirmOffer: 'Ommaviy taklifni (ofertani) tasdiqlang',
        enterCode: 'SMS-kodni kiriting',
        newPassword: 'Yangi parol',
        smsCodeReplay: 'Kod yozilgan SMSni qayta yuborish',
        sendCodeToMobile: 'Raqamingizga kod yozilgan SMS yuborildi',
        sendSmsLater: 'SMSni qayta yuborish',
        sendSmsAgain: 'SMSni qayta yuborish',
        authSuccess: "Avtorizatsiya muvaffaqiyatli o'tdi",
        error: "Ma'lumotlar to'g'ri to'ldirilganligini tekshiring",
        notValidLoginOrPassword: "Noto'g'ri login yoki parol kiritilgan",
        tryLaterAgain: "Xatolik yuz berdi. Bir ozdan keyin urinib ko'ring",
        editedPassword: "Parol o'zgartirildi",
        registed: "Ro'yxatdan o'tish",
        codeNotValid: "Noto'g'ri SMS-kod",
        registrationSuccess: "Ro'yxatdan muvaffaqiyatli o'tildi",
        alreadyUser: 'Bunday foydalanuvchi mavjud',
        repeatPassword: 'Parolni qayta tering',
        offer: 'Ommaviy oferta',
        back: 'Orqaga qaytish',
        cancel: 'Bekor qilish',
        sendCode: 'Kodni yuborish',
        checkCorrectData: "Ma'lumotlar to'g'ri to'ldirilganligini tekshiring",
        passwordChanged: "Parol o'zgartirildi",
      },
      updateQueuePage: {
        choiceBranch: 'Выберите филиал',
        choiceQueueType: 'Выберите тип очереди',
        queueUpdated: 'Очередь изменена',
        practice: 'Amaliyot',
        theory: 'Nazariya',
        fillFields: 'Заполните поля',
        tryAgainLater: 'Произошла ошибка, попробуйте позже',
        updateQueue: 'Изменить очередь',
        nameOz: 'Название на узбекском (кирилица)',
        nameUz: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      createQueuePage: {
        choiceBranch: 'Выберите филиал',
        choiceQueueType: 'Выберите тип очереди',
        queueCreated: 'Очередь создана успешно',
        practice: 'Практика',
        theory: 'Теория',
        fillFields: 'Заполните поля',
        tryAgainLater: 'Произошла ошибка, попробуйте позже',
        createQueue: 'Создать очередь',
        nameOz: 'Название на узбекском (кирилица)',
        nameUz: 'Название на узбекском (латиница)',
        nameRu: 'Название на русском',
        back: 'Назад',
        save: 'Сохранить',
      },
      carsPage: {
        filter: 'Фильтр',
        choiceMotordrome: 'Выберите автодром',
        search: 'Найти',
        number: 'Номер',
        model: 'Модель',
        category: 'Категория',
        cars: 'Автомобили',
      },
      queuePage: {
        branches: 'Филиал',
        confirmDelete: 'Удалить из очереди?',
        confirmLogout: 'Вы действительно хотите выйти?',
        usersCount: 'Navbatdagi odamlar soni',
        classRoom: 'Кабинет',
        selectClassRoom: 'Выберите кабинет',
        emptyQueue: 'Очередь пуста',
        confirmDeleteQueue:
          "Siz haqiqatan ham ushbu navbatni o'chirmoqchimisiz?",
        selectQueue: 'Navbatni tanlang',
        clearedQueue: 'Navbat tozalandi',
        clear: 'Tozalash',
        enterCount: 'Sonini kiriting',
        power: 'Ishga tushirish',
        name: 'Nomi',
        updateDate: "O'zgartirishlar kiritilgan sanasi",
        actions: 'Harakatlar',
        notAccept: 'Kirish rad etildi. Iltimos, qabulxonaga murojaat qiling',
        passNotValid: 'Beyjik haqiqiy emas',
        deletedQueue: "Navbat o'chirildi",
        queue: 'Navbat',
      },
    },
  },
};

export default data;
