import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { eavtotalimServices } from 'api/services';
import Template from 'components/Template/Template';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Link } from 'react-router-dom';
import {
  IEavtotalimAttrs,
  IResEavtotalim,
} from 'api/services/eavtotalim/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import moment from 'moment';

import './EavtotalimPage.sass';
import { ShowNotification } from 'helpers/showNotice';
import { NoticeStatus } from 'store/ducks/notice/types';

const statuses: { label: string; value: string }[] = [
  {
    label: 'Успешно',
    value: 'active',
  },
  {
    label: 'Не успешно',
    value: 'blocked',
  },
];

const EavtotalimPage: React.FC = () => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [applicationId, setApplicationId] = useState<number>();
  const [applicationPass, setApplicationPass] = useState('');
  const [data, setData] = useState<IResEavtotalim[]>([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const lang = useSelector((state: AppState) => state.i18n.key);

  useEffect(() => {
    getData();
  }, [page, take]);

  const getData = async (clear?: boolean) => {
    const pagination = {
      skip: page * take,
      take,
    };
    const params: IEavtotalimAttrs = {
      ...pagination,
      createdFrom: from,
      createdTo: to,
      applicationId,
      applicationPass,
      success:
        status === 'blocked'
          ? 'false'
          : status === 'active'
          ? 'true'
          : undefined,
    };
    const res = await eavtotalimServices
      .getEavtotalimItems(clear ? pagination : params)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    getData();
  };

  const clearHandler = () => {
    setFrom('');
    setTo('');
    setApplicationId(undefined);
    setApplicationPass('');
    setStatus('');
    setPage(0);
    getData(true);
  };

  const retrySend = async (id: number) => {
    if (loading) return;
    setLoading(true);
    try {
      await eavtotalimServices.retryEavtotalimItem(id);
      ShowNotification('Отправлено', NoticeStatus.SUCCESS);
      getData();
    } catch {
      ShowNotification('Ошибка, попробуйте позже');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className='eavtotalim-page'>
        <Grid xs={6} item>
          <Typography variant='h3'>{'Интеграция с гаи'}</Typography>
        </Grid>
        <form className='eavtotalim-page__form' onSubmit={search}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item md={4} xs={12}>
              <TextField
                value={applicationId}
                onChange={(e) => setApplicationId(Number(e.target.value))}
                label={'ID заявки'}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                value={applicationPass}
                onChange={(e) => setApplicationPass(e.target.value)}
                label={'Пропуск заявки'}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Статус</InputLabel>
                <Select
                  size='small'
                  value={status}
                  label={'Статус'}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {statuses.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                label={'Создан от'}
                autoComplete='off'
                variant='outlined'
                size='small'
                InputLabelProps={{ shrink: true }}
                type='date'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                value={to}
                onChange={(e) => setTo(e.target.value)}
                label={'Создан до'}
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={1.2} xs={3} style={{ marginLeft: 'auto' }}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='users-page__button'
              >
                {'Поиск'}
              </Button>
            </Grid>
            <Grid item md={1.2} xs={3}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='users-page__button'
              >
                {'Очистить'}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ID заявки</TableCell>
                <TableCell>Ф.И.О.</TableCell>
                <TableCell>ПИНФЛ</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Дата создания</TableCell>
                <TableCell>Дата изменения</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Ответ с гаи</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i.applicationId}</TableCell>
                  <TableCell>{`${i.application?.user?.lastName} ${i.application?.user?.firstName} ${i.application?.user?.middleName}`}</TableCell>
                  <TableCell>{i.requestBody?.passport_jshir}</TableCell>
                  <TableCell>+{i.application?.user?.phone}</TableCell>
                  <TableCell>
                    {moment(i.application.createdAt).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell>
                    {moment(i.application.updatedAt).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell>
                    {i.responseBody?.status === 1 ? (
                      <CheckCircleIcon className='eavtotalim-page__success' />
                    ) : (
                      <DoNotDisturbOnIcon className='eavtotalim-page__error' />
                    )}
                  </TableCell>
                  <TableCell>{i.responseBody?.message}</TableCell>
                  <TableCell>
                    <div className='eavtotalim-page__td'>
                      {i.responseStatus !== 200 && (
                        <Button
                          color='warning'
                          variant='contained'
                          className=''
                          size='small'
                          onClick={() => retrySend(i.id)}
                        >
                          Переотправить
                        </Button>
                      )}
                      <Link to={`/applications/${i.applicationId}`}>
                        <Button
                          color='success'
                          variant='contained'
                          className=''
                          size='small'
                        >
                          Перейти
                        </Button>
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {totalCount > take && (
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={take}
            labelRowsPerPage={paginationLocale.rowsPerPage}
            page={page}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        )}
      </div>
    </Template>
  );
};

export default EavtotalimPage;
