import instance from 'api/instance';
import qs from 'query-string';
import { IGetCertificates, IGetCertificatesFile, IGetCertificatesHistory, IGetEavtotalimCertificates, IUpdateCertificate, IUpdateCertificateData } from './types';

export const getCertificates: IGetCertificates = (params) =>
  instance.get(
    `/certificates?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getCertificatesFile: IGetCertificatesFile = () =>
  instance.get('/certificates/export');

export const updateCertificate: IUpdateCertificate = (id, data) => instance.patch(`/certificates/${id}/valid`, data)

export const getCertificatesHistory: IGetCertificatesHistory = (params) => instance.get(`/certificates/history?${qs.stringify(params, { skipEmptyString: true })}`)

export const updateCertificateData: IUpdateCertificateData = (id) => instance.put(`/certificates/${id}`)

export const getEavtotalimCertificates: IGetEavtotalimCertificates = (params) => instance.get(`/eavtotalim-certificates?${qs.stringify(params, { skipEmptyString: true })}`)