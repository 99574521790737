import { IResUser } from 'api/services/users/types';

export const isSuRole = (user: IResUser) => user?.role === 'SU';

export const isReceptionRole = (user: IResUser) => user?.role === 'RECEPTION';

export const isCashierRole = (user: IResUser) => user?.role === 'CASHIER';

export const isExamRole = (user: IResUser) => user?.role === 'EXAM';

export const isMotordromeRole = (user: IResUser) => user?.role === 'MOTORDROME';

export const isSecurityRole = (user: IResUser) => user?.role === 'SECURITY';

export const isUzakovaRole = (user: IResUser) => {
  return user?.role === 'UZAKOVA';
};

export const isBranchHead = (user: IResUser) => {
  return user?.role === 'BRANCH_HEAD';
};

export const isAssistantBranchHead = (user: IResUser) => {
  return user?.role === 'ASSISTANT_BRANCH_HEAD';
};

export const isQueueRole = (user: IResUser) => {
  return user?.role === 'QUEUE';
};

export const isGai = (user: IResUser) => {
  return user?.role === 'GAI';
};

export const isAccountant = (user: IResUser) => {
  return user?.role === 'ACCOUNTANT';
};

export const isHeadAccountant = (user: IResUser) => {
  return user?.role === 'HEAD_ACCOUNTANT';
};

export const isQueueManagerRole = (user: IResUser) => {
  return user?.role === 'QUEUE_MANAGER';
};

export const isHeadRole = (user: IResUser) => {
  return user?.role === 'HEAD';
};

export const isUserRole = (user: IResUser) => user?.role === 'USER';

export const isReportRole = (user: IResUser) => user?.role === 'REPORT';

export const isUMCHRole = (user: IResUser) => user?.role === 'UMCH';

export const isAppealRole = (user: IResUser) => user?.role === 'APPEAL';

export const isSaturdayEditor = (user: IResUser) =>
  user?.role === 'SATURDAY_EDITOR';

export const isHeadOfIT = (user: IResUser) => user?.role === 'HEAD_OF_IT';
