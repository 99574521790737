

import { certificateServices } from 'api/services'
import './CertificateHistoryModal.sass'
import { useState } from 'react'
import { ICertificate, ICertificateHistory } from 'api/services/certificates/types'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import moment from 'moment'

interface Props {
  data: ICertificateHistory[]
}


const CertificateHistoryModal: React.FC<Props> = ({
  data
}) => {
  const [vehicleType, setVehicleType] = useState<IVehicleType>()
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [loading, setLoading] = useState(false)
  

  return (
    <div className="certificate-history-modal" onClick={(e) => e.stopPropagation()}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Дата создания</TableCell>
            <TableCell>Ф.И.О.</TableCell>
            <TableCell>Дата рождения</TableCell>
            <TableCell>Категория</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((i) => (
            <TableRow key={i.id}>
              <TableCell>{moment(i.createdAt).format('DD.MM.YYYY')}</TableCell>
              <TableCell>{i.lastName} {i.firstName} {i.middleName}</TableCell>
              <TableCell>{i.dateOfBirth}</TableCell>
              <TableCell>{i.vehicleType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default CertificateHistoryModal