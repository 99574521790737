import qs from 'query-string';
import instance from 'api/instance';
import { IActivateEavtotalim, IGetEavtotalim, IRetryEavtotalim } from './types';

export const getEavtotalimItems: IGetEavtotalim = (attrs) =>
  instance.get(`/eavtotalim?${qs.stringify(attrs, { skipEmptyString: true })}`);

export const retryEavtotalimItem: IRetryEavtotalim = (id) =>
  instance.post(`/eavtotalim/${id}/retry`);

export const activateEavtotalimItem: IActivateEavtotalim = (id) =>
  instance.put(`/eavtotalim/${id}/status/active`);

export const disableEavtotalimItem: IActivateEavtotalim = (id) =>
  instance.put(`/eavtotalim/${id}/status/disabled`);
